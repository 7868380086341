import React, { useEffect } from 'react'
import styled from 'styled-components'

const MainDownloadContent = () => {
  return (
    <MainDownloadContentWrap>
      <MainDownloadContentInnerWrap>
        <MainDownloadContentHeader>
          <MainDownloadContentHeaderInfo>MT4 / MT5 다운로드</MainDownloadContentHeaderInfo>
          <MainDownloadContentHeaderLine />
        </MainDownloadContentHeader>
        <MainDownloadContentImgWrap>
          <MainDownloadContentImg src={`${process.env.PUBLIC_URL}/img/main/htc3.png`} />
          <MainDownloadContentImgInfo>
            1000가지가 넘는 상품을 거래하세요. 언제 어디서나! <br />
            Forex, 주가지수 CFD, 원자재, 주식, 금속, 에너지
          </MainDownloadContentImgInfo>
        </MainDownloadContentImgWrap>
      </MainDownloadContentInnerWrap>
    </MainDownloadContentWrap>
  )
}

export default MainDownloadContent

const MainDownloadContentWrap = styled.div`
  width: 100%;

  height: 650px;

  background: #2f2f2f;
`

const MainDownloadContentInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`

const MainDownloadContentHeader = styled.div`
  width: 100%;
  position: relative;
  top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const MainDownloadContentHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  text-align: center;

  color: #fff;
`

const MainDownloadContentHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #007bff;
  display: block;
`

const MainDownloadContentImgWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  //   border: 1px solid red;

  position: relative;
  top: 90px;

  gap: 30px;
`

const MainDownloadContentImg = styled.img`
  width: 790px;
  height: 358px;
`

const MainDownloadContentImgInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #fff;
`
