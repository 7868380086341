import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const Promotion = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <br />
          프로모션
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>프로모션 안내</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ marginBottom: '10px' }}>
          ※ 프로모션 기간내 가입 하신 고객분은 프로모션 종료 후에도 이벤트 혜택이 평생 유지됩니다.
        </CompanyIntroductionP>

        <br />
        <CompanyIntroductionP style={{ color: 'red', fontWeight: '600' }}>
          <img
            src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300429_0094.jpg"
            width={140}
            height={140}
            alt=""
          />
        </CompanyIntroductionP>
        <CompanyIntroductionH4 style={{ marginTop: '-20px' }}>입금 보너스</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ color: 'red', fontWeight: '600' }}>
          당일 거래 시 즉시 출금 가능
        </CompanyIntroductionP>
        <div
          style={{
            height: '70px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            paddingLeft: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            회원 가입 후 100만원 이상 입금하신 고객 분들에게는 10만원 축하금 지급
          </CompanyIntroductionP>
        </div>
        <CompanyIntroductionP style={{ color: 'red', fontWeight: '600' }}>
          <img
            src="	https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300551_2429.jpg"
            width={140}
            height={140}
            alt=""
          />
        </CompanyIntroductionP>
        <CompanyIntroductionH4 style={{ marginTop: '-20px' }}>출석 보너스</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ color: 'red', fontWeight: '600' }}>
          출금 조건 없이, 매주 5만원 출석금 지급
        </CompanyIntroductionP>
        <div
          style={{
            height: '70px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            paddingLeft: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            월요일 부터 금요일, 4일 거래 일수와 최종 총손익 ±500,000원 이상 조건 충족 시 매주 50,000원 출석 보너스 지급
          </CompanyIntroductionP>
        </div>
        <CompanyIntroductionP style={{ fontWeight: '600' }}>
          <img
            src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300571_1036.jpg"
            width={140}
            height={140}
            alt=""
          />
        </CompanyIntroductionP>
        <CompanyIntroductionH4 style={{ marginTop: '-20px' }}>수수료 대폭 할인</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ color: 'red', fontWeight: '600' }}>업계 최저 수수료 보장</CompanyIntroductionP>
        <div
          style={{
            height: '240px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            paddingLeft: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            국내선물 수수료 0.0012(편도 1,000원)
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            국내옵션 수수료 0.10%
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            해외선물 수수료 3$(편도3,300원)
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            주식 수수료 0.03 ~ 0.05 (거래량에 따라 이용수수료 상의)
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            화페 수수료 (이용 수수료 협의)
          </CompanyIntroductionP>
        </div>
        <CompanyIntroductionP style={{ fontWeight: '600' }}>
          <img
            src="	https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300596_6402.jpg"
            width={140}
            height={140}
            alt=""
          />
        </CompanyIntroductionP>
        <CompanyIntroductionH4 style={{ marginTop: '-20px' }}>수수료 환급</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ color: 'red', fontWeight: '600' }}>
          이용률에 따라 환급 % 상향
        </CompanyIntroductionP>
        <div
          style={{
            height: '70px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            paddingLeft: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ textAlign: 'left' }}>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            월요일 부터 금요일, 3일 거래 일수와 최종 수수료 500,000원 이상 조건 충족 시 총 발생 수수료 금액의 10% 환급
          </CompanyIntroductionP>
        </div>

        <div
          style={{
            height: '100px',
            background: 'red',
            marginTop: '30px',
            color: 'white',
            fontWeight: '600',
            textAlign: 'center',
            verticalAlign: 'left',
            paddingLeft: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ textAlign: 'center' }}>※ 필독※</CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'center' }}>
            프로모션 기간내 제공되는 혜택이 큰 만큼 프로모션 종료 후 이후에 가입하신 고객분은들 제공되는 혜택이 변동 될
            수 있습니다.
          </CompanyIntroductionP>
        </div>
      </CompanyIntroductionContent>
    </CompanyIntroductionWrap>
  )
}
export default Promotion

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  // padding: 0 0 0 20px;

  // display: flex;
  // align-items: center;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
