import React from 'react'
import styled from 'styled-components'

const MainContent1 = () => {
  return (
    <MainContent1Wrap>
      <MainContent1InnerWrap>
        <MainContent1TitleWrap>
          <MainContent1Title>제공 선물상품</MainContent1Title>
        </MainContent1TitleWrap>
        <MainContent1ContentWrap>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main01.png`} />
            <MainContent1ContentTitle>금리선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              금융선물의 종류 중 하나입니다. 대표적인 상품으로 미국 재무성채권과 유로 달러 독일 금리선물 등이 있습니다.
            </MainContent1ContentContent>
          </MainContent1Content>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main02.png`} />
            <MainContent1ContentTitle>주가지수선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              금융선물의 종류 중 하나입니다. 미국 다우지수, S&P 500, 나스닥지수, 일본 니케이, 홍콩 항셍지수 등이
              있습니다.
            </MainContent1ContentContent>
          </MainContent1Content>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main03.png`} />
            <MainContent1ContentTitle>통화선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              금융선물의 종류 중 하나입니다. 호주달러화, 영국파운드화, 유로화, 캐나다달러화, 스위스프랑화, 일본엔화 등을
              중심으로 선물 거래가 이루어집니다.
            </MainContent1ContentContent>
          </MainContent1Content>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main04.png`} />
            <MainContent1ContentTitle>상품선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              원유, 난방유, 가솔린 등 에너지와 비철금속, 귀금속, 농산물, 커피, 코코아 등의 연성상품과 소, 돼지고기 등의
              축산물을 중심의 상품선물이 있습니다.
            </MainContent1ContentContent>
          </MainContent1Content>
        </MainContent1ContentWrap>
      </MainContent1InnerWrap>
    </MainContent1Wrap>
  )
}

export default MainContent1

const MainContent1Wrap = styled.div`
  width: 100%;
  min-height: 650px;
  position: relative;

  background: #f8f8f6;

  padding: 30px 0;
`

const MainContent1InnerWrap = styled.div`
  width: 1260px;
  height: 620px;
  margin: 0 auto;
  border-radius: 16px;
  background: white;

  display: flex;
  flex-direction: column;

  padding: 80px 123px;
`

const MainContent1TitleWrap = styled.div`
  display: block;
  color: #000;
  text-align: center;
`

const MainContent1Title = styled.p`
  font-size: 36px;
  line-height: 45px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`
const MainContent1SubTitle = styled.span`
  font-size: 18px;
  line-height: 22.59px;
  font-weight: 400;
`

const MainContent1ContentWrap = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const MainContent1Content = styled.div`
  width: 180px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-top: 30px;
  color: #252831;
`
const MainContent1ContentImg = styled.img`
  vertical-align: middle;
  border-style: none;

  height: 180px;
`

const MainContent1ContentTitle = styled.p`
  margin-top: 20px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
`

const MainContent1ContentContent = styled.div`
  margin-top: 24px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
`
