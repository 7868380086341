import React from 'react'
import { isMobile } from 'react-device-detect'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './Footer'
import Header from './Header'
import MobileHeader from './MobileHeader'
import ScrollButton from './ScrollButton'
import MobileFooter from './MobileFooter'

// TODO  https://fourwingsy.medium.com/%ED%8C%9D%EC%97%85%EC%99%80-%EB%A9%94%EC%8B%9C%EC%A7%80-with-react-afd1923797d8
const Layout = () => {
  const navigate = useNavigate()

  return (
    <>
      {isMobile ? <MobileHeader /> : <Header />}

      <Container $isMobile={isMobile}>
        <Outlet />
      </Container>

      {isMobile ? <MobileFooter /> : <Footer />}
    </>
  )
}

export default Layout

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 750px;
  // margin-top: 81px;
  margin-top: ${props => (props.$isMobile ? '70px' : '74px')};

  // margin: 0 auto;
  // position: relative;

  // top: ${props => (props.$isMobile ? '90px' : '65px')};
`
