import { clearLocalStorage, setUserId } from '../../utils/authority'
import axios from '../base/axios'

// 회원 가입
export async function siginUp(body) {
  const response = await axios({
    url: '/traders/v1/auth/sign-up',
    method: 'POST',
    data: body,
  }).then(res => {
    return res.data
  })
  return response
}

// 로그인
export async function siginIn(body) {
  const response = await axios({
    url: '/traders/v1/auth/sign-in',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 로그아웃
export async function siginOut() {
  const response = await axios({
    url: '/traders/v1/auth/sign-out',
    method: 'POST',
  }).then(res => {
    // local storage 데이터 전부 삭제
    clearLocalStorage()

    return res
  })

  return response
}

// 이메일 확인 -> 아이디 비밀번호 찾기
export async function findMemberInfo(body) {
  const response = await axios({
    url: '/traders/v1/auth/confirm-email',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 비밀번호 변경
export async function updateMemberPwd(body) {
  const response = await axios({
    url: '/traders/v1/auth/info-pwd',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
