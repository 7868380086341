import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const CompanyIntroduction = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>회사소개</CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionP>
          <CompanyIntroductionImg src={`${process.env.PUBLIC_URL}/img/main/company1.png`} alt="" />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>2012년 설립</span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          대영은 국내 최대 규모의 주식, 선물 중개 법인 업체입니다. 50개 이상의 제휴업체와 5개의 계열사로 구성되어
          전문적인 거래 플랫폼 제공, 거래의 방향성 제시, 자산의 포토폴리오 설계와 안전한 트레이딩 환경을 위하여 끊임없이
          혁신하고 변화합니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <CompanyIntroductionImg src={`${process.env.PUBLIC_URL}/img/main/company2.png`} alt="" />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>대영 TRADERS </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          선물과 화폐의 거래 중계와 카피 트레이딩 시스템을 제공합니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}> 대영 </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          주식 상품의 거래 중계와 카피 트레이딩 시스템을 제공합니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>대영 TRADING </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          최고 수익률의 트레이더(전문가) 양성과 교육방송을 지원합니다. 소액과 고액 투자자를 구분하지 않고 오로지
          전문적인 트레이더를 통해 올바른 투자 방법을 배우게 됩니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP>
          <CompanyIntroductionImg src={`${process.env.PUBLIC_URL}/img/main/company3.png`} alt="" />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>DAIL SYSTEM </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          데이터 안전, 기술 안전, 네트워크 안전, 프라이버시 안전, 고객정보 보안 등. 기술을 바탕으로 오직 거래에만 집중
          할 수 있도록 안전한 서비스를 제공합니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img
              src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300048_8381.png"
              alt=""
            />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>INVESTING </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          주식 시장의 시세와 거래 전략, 사실 정보만을 제공합니다. 거래를 참여하는 고객 분들은 수 많은 정보를 통해
          수익향상과 자금 관리의 큰 도움을 얻게 됩니다.
        </CompanyIntroductionP>
      </CompanyIntroductionContent>
      {/* <CustomerCenterHeaderWrap>
        <CustomerCenterHeaderInfo>공지사항</CustomerCenterHeaderInfo>
      </CustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <CustomerCenterContentWrap>
          {isShowBoardContent ? (
            <NoticeContent boardContent={boardContent} setIsShowBoardContent={setIsShowBoardContent} />
          ) : (
            <ReactTableBase columns={boardListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          )}
        </CustomerCenterContentWrap>
      )} */}
    </CompanyIntroductionWrap>
  )
}
export default CompanyIntroduction

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  // padding: 0 0 0 20px;

  // display: flex;
  // align-items: center;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`
export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const CompanyIntroductionImg = styled.img`
  width: 100%;
  height: 371px;
`
