import React from 'react'
import styled from 'styled-components'

const UserGuidePage = () => {
  return (
    <UserGuidePageWrap>
      <UserGuidePageInnerWrap>
        <UserGuidePageHeader>
          <UserGuidePageH3>이용 가이드</UserGuidePageH3>
        </UserGuidePageHeader>
        {/* <UserGuidePageInnerTitle>동영상 안내</UserGuidePageInnerTitle>
        <UserGuidePageVideoWrap>
          <UserGuidePageVideo
            src="https://ivbk.s3.ap-northeast-3.amazonaws.com/hana-asset/%E1%84%92%E1%85%A1%E1%84%82%E1%85%A1%E1%84%8B%E1%85%A6%E1%84%89%E1%85%A6%E1%86%BA.mp4"
            type="video/mp4"
            controls
          />
        </UserGuidePageVideoWrap>

        <hr /> */}
        {/* <UserGuidePageInnerTitle>이미지 안내</UserGuidePageInnerTitle> */}
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-1.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-2.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-3.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-4.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-5.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-6.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-7.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-8.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-9.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-10.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-11.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-12.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-13.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-14.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-15.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-16.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-17.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-18.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-19.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-20.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-21.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-22.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-23.png`} alt="" />
        <UserGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/user-guide-24.png`} alt="" />
      </UserGuidePageInnerWrap>
    </UserGuidePageWrap>
  )
}

export default UserGuidePage

const UserGuidePageWrap = styled.div`
  width: 100%;
  background: #f8f8f6;
  padding: 80px 0;
`
const UserGuidePageHeader = styled.div`
  position: relative;
`
const UserGuidePageH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

const UserGuidePageInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  // min-height: 17370px;

  border-radius: 16px;
  background: white;

  padding: 10px 40px;
  padding-bottom: 35px;

  display: flex;
  flex-direction: column;

  gap: 20px;
`

const UserGuidePageVideoWrap = styled.div`
  width: 100%;
`

const UserGuidePageVideo = styled.video`
  width: 100%;
  height: 100%;
`

const UserGuidePageImg = styled.img`
  width: 100%;
  height: 100%;
`

const UserGuidePageInnerTitle = styled.div`
  width: 100%;
  font-size: 22px;
  line-height: 25px;
  color: black;
`
