import React from 'react'
import styled from 'styled-components'

const MobileMainContent2 = () => {
  return (
    <MainContent2Wrap>
      <MainContent2InnerWrap>
        <MainContent2TitleWrap>
          <MainContent2Title>대영만의 제공</MainContent2Title>
        </MainContent2TitleWrap>
        <MainContent2Content>
          <MainContent2ContentImg src={`${process.env.PUBLIC_URL}/img/main/main05.png`} />
          <MainContent2ContentInner>
            <MainContent2ContentTitle>해외 선물상품 거래</MainContent2ContentTitle>
            <MainContent2ContentContent>
              {/* A few clicks is all it takes to get into the next moon shot */}
            </MainContent2ContentContent>
            <ul>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                몇 번의 클릭으로 해외 주요 선물상품의 빠른 거래 체결
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                NASDAQ, S&P500 등 세계 주요 지수 상품의 거래 가능
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                100,000건 이상의 TPS 제공
              </li>
            </ul>
          </MainContent2ContentInner>
        </MainContent2Content>
        <MainContent2Content>
          <MainContent2ContentImg src={`${process.env.PUBLIC_URL}/img/main/main06.png`} />
          <MainContent2ContentInner>
            <MainContent2ContentTitle>TRADING</MainContent2ContentTitle>
            <MainContent2ContentContent>{/* Trade crypto & keep more of your profits */}</MainContent2ContentContent>
            <ul>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                동종 업계 최저 수수료
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                최고수준의 UI/UX를 포함한 최적의 거래환경
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                고도화 된 트레이딩 툴
              </li>
            </ul>
          </MainContent2ContentInner>
        </MainContent2Content>
        <MainContent2Content>
          <MainContent2ContentImg src={`${process.env.PUBLIC_URL}/img/main/main07.png`} />
          <MainContent2ContentInner>
            <MainContent2ContentTitle>거래편의성</MainContent2ContentTitle>
            <MainContent2ContentContent>{/* Up your trading game with 5x leverage */}</MainContent2ContentContent>
            <ul>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                전문 트레이더들에 의해 고안된 트레이딩 시스템
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                빠른 체결속도와 최소화 된 슬리피지
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`}
                  alt=""
                  style={{ height: '12px', marginRight: '5px' }}
                />
                웹 MTS적용으로 어떤 환경에서도 거래 가능
              </li>
            </ul>
          </MainContent2ContentInner>
        </MainContent2Content>
      </MainContent2InnerWrap>
    </MainContent2Wrap>
  )
}

export default MobileMainContent2

const MainContent2Wrap = styled.div`
  width: 100%;
  min-height: 1200px;
  position: relative;
  background: #f8f8f6;
  padding: 12px;
`

const MainContent2InnerWrap = styled.div`
  width: 100%;
  height: 1160px;
  margin: 0 auto;
  border-radius: 16px;
  background: white;

  display: flex;
  flex-direction: column;
  padding: 20px;
`

const MainContent2TitleWrap = styled.div`
  display: block;
  color: #000;
  text-align: center;
`

const MainContent2Title = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`

const MainContent2ContentImg = styled.img`
  // vertical-align: middle;
  // border-style: none;

  width: 180px;
  height: 180px;
  margin-bottom: 36px;
`

const MainContent2ContentTitle = styled.p`
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #252831;
  text-align: left;
`

const MainContent2ContentContent = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #252831;
  text-align: left;
`

const MainContent2ContentInner = styled.div`
  width: 100%;
  ul li {
    // position: relative;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #252831;
  }
`

const MainContent2Content = styled.div`
  margin-top: 30px;
  text-align: left;
  // position: relative;
  // height: 338px;
  color: #252831;

  display: flex;
  flex-direction: column;

  padding: 10px;
  align-items: center;
`
