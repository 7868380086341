import React, { useEffect } from 'react'
import styled from 'styled-components'

const MobileMeritContent = () => {
  return (
    <MeritContentWrap url={`${process.env.PUBLIC_URL}/img/main/bg1.jpg`}>
      <MeritContentInnerWrap>
        <MeritContentHeader1>
          <MeritContentHeaderInfo1>대영의 장점</MeritContentHeaderInfo1>
          <MeritContentHeaderLine1 />
        </MeritContentHeader1>
        <MeritContentInnerContentWrap1>
          <MeritContentHeaderInnerWrap>
            <MeritContentHeaderInnerInfo>● 하나의 프로그램으로 주식과 선물 동시 거래 가능</MeritContentHeaderInnerInfo>
            <MeritContentHeaderInnerInfo>● 무료 모의거래 제공</MeritContentHeaderInnerInfo>
          </MeritContentHeaderInnerWrap>
          <MeritContentHeaderInnerWrap>
            <MeritContentHeaderInnerInfo>● 가장 저렴한 수수료</MeritContentHeaderInnerInfo>
            <MeritContentHeaderInnerInfo>● 가입 시 제공되는 다양한 이벤트</MeritContentHeaderInnerInfo>
          </MeritContentHeaderInnerWrap>
          <MeritContentHeaderInnerWrap>
            <MeritContentHeaderInnerInfo>● 5곳의 우량 계열사의 정보 제공</MeritContentHeaderInnerInfo>
            <MeritContentHeaderInnerInfo>● 가장 우수한 프로그램 DHTS 제공</MeritContentHeaderInnerInfo>
          </MeritContentHeaderInnerWrap>
          <MeritContentHeaderInnerWrap>
            <MeritContentHeaderInnerInfo>● 전문적인 트레이더 추천(교육방송/포지션 제공)</MeritContentHeaderInnerInfo>
            <MeritContentHeaderInnerInfo>● 최대 100계약의 수량을 제공합니다.</MeritContentHeaderInnerInfo>
          </MeritContentHeaderInnerWrap>
        </MeritContentInnerContentWrap1>
        <MeritContentHeader2 style={{ marginTop: '40px' }}>
          <MeritContentHeaderInfo2>거래 상품</MeritContentHeaderInfo2>
          <MeritContentHeaderLine2 />
        </MeritContentHeader2>
        <MeritContentInnerContentWrap2>
          <MeritContentImgWrap>
            <MeritContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_1.jpg`} style={{ marginLeft: '32px' }} />
            <MeritContentImgInfo>해외선물</MeritContentImgInfo>
          </MeritContentImgWrap>
          <MeritContentImgWrap>
            <MeritContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_2.jpg`} style={{ marginLeft: '32px' }} />
            <MeritContentImgInfo>해외주식</MeritContentImgInfo>
          </MeritContentImgWrap>
        </MeritContentInnerContentWrap2>
        <MeritContentInnerContentWrap2 style={{ marginTop: '10px' }}>
          <MeritContentImgWrap>
            <MeritContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_3.jpg`} />
            <MeritContentImgInfo>귀금속</MeritContentImgInfo>
          </MeritContentImgWrap>
          <MeritContentImgWrap>
            <MeritContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_4.jpg`} />
            <MeritContentImgInfo>외환</MeritContentImgInfo>
          </MeritContentImgWrap>
          <MeritContentImgWrap>
            <MeritContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_5.jpg`} />
            <MeritContentImgInfo>암호화폐</MeritContentImgInfo>
          </MeritContentImgWrap>
        </MeritContentInnerContentWrap2>
      </MeritContentInnerWrap>
    </MeritContentWrap>
  )
}

export default MobileMeritContent

const MeritContentWrap = styled.div`
  width: 100%;
  min-height: 920px;
  background: ${props => props.url && `url(${props.url})`} no-repeat center;
  background-size: cover;

  position: relative;
`

const MeritContentInnerWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`

const MeritContentHeader1 = styled.div`
  width: 100%;
  position: relative;
  top: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const MeritContentInnerContentWrap1 = styled.div`
  width: 100%;
  position: relative;
  top: 90px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 30px;
`

const MeritContentHeaderInfo1 = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  text-align: center;

  color: #fff;
`

const MeritContentHeaderLine1 = styled.div`
  width: 90px;
  border: 1.5px solid #007bff;
  display: block;
`

const MeritContentHeaderInnerWrap = styled.div`
  width: 100%;
  display: flex;
`

const MeritContentHeaderInnerInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;

  color: #fff;

  flex: 1;
`

const MeritContentHeader2 = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: -80px;
  color: white;
  position: relative;
  top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const MeritContentInnerContentWrap2 = styled.div`
  width: 100%;
  position: relative;
  top: 210px;

  display: flex;
  //   flex-direction: column;
  justify-content: space-between;
  //   align-items: center;

  gap: 30px;
`

const MeritContentHeaderInfo2 = styled.span`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  text-align: center;

  color: #fff;
`

const MeritContentHeaderLine2 = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

const MeritContentImgWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const MeritContentImg = styled.img`
  width: 100px;
  height: 100px;

  border-radius: 50%;
`

const MeritContentImgInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #fff;
`
