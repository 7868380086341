import React from 'react'
import styled from 'styled-components'
import { MarketData } from 'react-ts-tradingview-widgets'

const MainTraderPosition = () => {
  return (
    <MainTraderPositionContentWrap>
      <MainTraderPositionContentInnerWrap>
        <MainTraderPositionContentHeader>
          <MainTraderPositionContentHeaderInfo>트레이더 포지션</MainTraderPositionContentHeaderInfo>
          <MainTraderPositionContentHeaderLine />
        </MainTraderPositionContentHeader>
        <MarketDataWrap>
          <MarketData
            colorTheme="light"
            width="100%"
            height={790}
            copyrightStyles={{
              parent: {
                display: 'none',
              },
            }}
            locale="kr"
            showSymbolLogo={false}
            symbolsGroups={[
              {
                name: '지수',
                originalName: '지수',
                symbols: [
                  {
                    name: 'FOREXCOM:SPXUSD',
                    displayName: 'S&P 500',
                  },
                  {
                    name: 'FOREXCOM:NSXUSD',
                    displayName: 'Nasdaq 100',
                  },
                  {
                    name: 'FOREXCOM:DJI',
                    displayName: 'Dow 30',
                  },
                  {
                    name: 'INDEX:NKY',
                    displayName: 'Nikkei 225',
                  },
                  {
                    name: 'INDEX:DEU30',
                    displayName: 'DAX Index',
                  },
                  {
                    name: 'FOREXCOM:UKXGBP',
                    displayName: 'UK 100',
                  },
                ],
              },
              {
                name: '상품',
                originalName: '상품',
                symbols: [
                  {
                    name: 'CME_MINI:ES1!',
                    displayName: 'S&P 500',
                  },
                  {
                    name: 'CME:6E1!',
                    displayName: 'Euro',
                  },
                  {
                    name: 'COMEX:GC1!',
                    displayName: 'Gold',
                  },
                  {
                    name: 'NYMEX:CL1!',
                    displayName: 'Crude Oil',
                  },
                  {
                    name: 'NYMEX:NG1!',
                    displayName: 'Natural Gas',
                  },
                  {
                    name: 'CBOT:ZC1!',
                    displayName: 'Corn',
                  },
                ],
              },
              {
                name: '외환',
                originalName: '외환',
                symbols: [
                  {
                    name: 'CME:GE1!',
                    displayName: 'Eurodollar',
                  },
                  {
                    name: 'CBOT:ZB1!',
                    displayName: 'T-Bond',
                  },
                  {
                    name: 'CBOT:UB1!',
                    displayName: 'Ultra T-Bond',
                  },
                  {
                    name: 'EUREX:FGBL1!',
                    displayName: 'Euro Bund',
                  },
                  {
                    name: 'EUREX:FBTP1!',
                    displayName: 'Euro BTP',
                  },
                  {
                    name: 'EUREX:FGBM1!',
                    displayName: 'Euro BOBL',
                  },
                ],
              },
            ]}
          />
        </MarketDataWrap>
      </MainTraderPositionContentInnerWrap>
    </MainTraderPositionContentWrap>
  )
}

export default MainTraderPosition

const MainTraderPositionContentWrap = styled.div`
  width: 100%;
  min-height: 980px;
  position: relative;

  background: #f8f8f6;

  // padding: 5px 0;
  padding: 30px 0;
`

const MainTraderPositionContentInnerWrap = styled.div`
  width: 1260px;
  height: 950px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  border-radius: 16px;

  padding: 0 10px;

  background: white;
`

const MainTraderPositionContentHeader = styled.div`
  width: 100%;
  position: relative;
  top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const MainTraderPositionContentHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  text-align: center;

  color: #000;
`
const MainTraderPositionContentHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

const MarketDataWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 80px;
`
