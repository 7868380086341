import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const ForeignGift = () => {
  return (
    <div
      style={{
        width: '100%',
        background: '#f8f8f6',
        padding: '80px 0',
      }}
    >
      <CompanyIntroductionWrap>
        <CompanyIntroductionHeader>
          <CompanyIntroductionH3>
            {/* <img src="https://daol1234.kr/img/p_02.jpg" width={199} height={199} alt="" />
            <br /> */}
            해외선물
          </CompanyIntroductionH3>
        </CompanyIntroductionHeader>
        <CompanyIntroductionContent>
          <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '60px' }}>
            <thead>
              <tr style={{ fontSize: '20px', borderBottom: '2px solid #dee2e6', borderTop: '2px solid #dee2e6' }}>
                <GiftTh colSpan={7}>지수 상품</GiftTh>
              </tr>
              <tr>
                <GiftTh>대상품목</GiftTh>
                <GiftTh>매매시간</GiftTh>
                <GiftTh>틱단위</GiftTh>
                <GiftTh>틱가치</GiftTh>
                <GiftTh>강제청산</GiftTh>
                <GiftTh>만기일</GiftTh>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>미니 S&P500</GiftTd>
                <GiftTd>08:30 ~ 06:00(익일)</GiftTd>
                <GiftTd>0.25</GiftTd>
                <GiftTd>12.5$</GiftTd>
                <GiftTd>06:00(익일)</GiftTd>
                <GiftTd>
                  3, 6, 9, 12월
                  <br />
                  <br /> 셋째주 금요일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>미니 나스닥</GiftTd>
                <GiftTd>08:30 ~ 06:00(익일)</GiftTd>
                <GiftTd>0.25</GiftTd>
                <GiftTd>5$</GiftTd>
                <GiftTd>06:00(익일)</GiftTd>
                <GiftTd>
                  3, 6, 9, 12월
                  <br />
                  <br /> 셋째주 금요일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>미니 다우</GiftTd>
                <GiftTd>08:30 ~ 06:00(익일)</GiftTd>
                <GiftTd>1</GiftTd>
                <GiftTd>5$</GiftTd>
                <GiftTd>06:00(익일)</GiftTd>
                <GiftTd>
                  3, 6, 9, 12월
                  <br />
                  <br /> 셋째주 금요일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>니케이 225</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>5</GiftTd>
                <GiftTd>JPY 500</GiftTd>
                <GiftTd>17:30(익일)</GiftTd>
                <GiftTd>
                  3, 6, 9, 12월
                  <br />
                  <br /> 둘째주 목요일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>차이나 A50</GiftTd>
                <GiftTd>10:00 ~ 02:55(익일)</GiftTd>
                <GiftTd>0.5</GiftTd>
                <GiftTd>2.5$</GiftTd>
                <GiftTd>02:55(익일)</GiftTd>
                <GiftTd>매달 최종 거래일</GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>항셍 지수</GiftTd>
                <GiftTd>10:16 ~ 03:59(익일)</GiftTd>
                <GiftTd>1</GiftTd>
                <GiftTd>HKD 50</GiftTd>
                <GiftTd>03:59(익일)</GiftTd>
                <GiftTd>매달 최종 거래일</GiftTd>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
            <thead>
              <tr style={{ fontSize: '20px', borderBottom: '2px solid #dee2e6', borderTop: '2px solid #dee2e6' }}>
                <GiftTh colSpan={7}>금리 상품</GiftTh>
              </tr>
              <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
                <GiftTh>대상품목</GiftTh>
                <GiftTh>매매시간</GiftTh>
                <GiftTh>틱단위</GiftTh>
                <GiftTh>틱가치</GiftTh>
                <GiftTh>강제청산</GiftTh>
                <GiftTh>만기일</GiftTh>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6', borderBottom: 'solid 2px #dee2e6' }}>
                <GiftTd>미국 10년 국채</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0'00.50</GiftTd>
                <GiftTd>15.625$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 5, 8, 11월
                  <br />
                  <br /> 최종 거래일
                </GiftTd>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
            <thead>
              <tr style={{ fontSize: '20px', borderBottom: '2px solid #dee2e6', borderTop: '2px solid #dee2e6' }}>
                <GiftTh colSpan={7}>농축산 상품</GiftTh>
              </tr>
              <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
                <GiftTh>대상품목</GiftTh>
                <GiftTh>매매시간</GiftTh>
                <GiftTh>틱단위</GiftTh>
                <GiftTh>틱가치</GiftTh>
                <GiftTh>강제청산</GiftTh>
                <GiftTh>만기일</GiftTh>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>돈육</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.0025</GiftTd>
                <GiftTd>10$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br /> <br /> 최종 거래일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>쌀</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.5</GiftTd>
                <GiftTd>10$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br /> <br /> 최종 거래일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>옥수수</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>1</GiftTd>
                <GiftTd>12.5$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br /> <br /> 최종 거래일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>소맥</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>1</GiftTd>
                <GiftTd>12.5$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br /> <br /> 최종 거래일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>대두</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>1</GiftTd>
                <GiftTd>12.5$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br /> <br /> 최종 거래일
                </GiftTd>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
            <thead>
              <tr style={{ fontSize: '20px', borderBottom: '2px solid #dee2e6', borderTop: '2px solid #dee2e6' }}>
                <GiftTh colSpan={7}>비철금속 상품</GiftTh>
              </tr>
              <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
                <GiftTh>대상품목</GiftTh>
                <GiftTh>매매시간</GiftTh>
                <GiftTh>틱단위</GiftTh>
                <GiftTh>틱가치</GiftTh>
                <GiftTh>강제청산</GiftTh>
                <GiftTh>만기일</GiftTh>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6', borderBottom: 'solid 2px #dee2e6' }}>
                <GiftTd>구리</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.0005</GiftTd>
                <GiftTd>12.5$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br />
                  <br /> 최종 거래일
                </GiftTd>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
            <thead>
              <tr style={{ fontSize: '20px', borderBottom: '2px solid #dee2e6', borderTop: '2px solid #dee2e6' }}>
                <GiftTh colSpan={7}>귀금속 상품</GiftTh>
              </tr>
              <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
                <GiftTh>대상품목</GiftTh>
                <GiftTh>매매시간</GiftTh>
                <GiftTh>틱단위</GiftTh>
                <GiftTh>틱가치</GiftTh>
                <GiftTh>강제청산</GiftTh>
                <GiftTh>만기일</GiftTh>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>금</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.1</GiftTd>
                <GiftTd>10$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  1, 3, 5, 7, 9, 11월
                  <br />
                  <br /> 최종 거래일
                </GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6', borderBottom: 'solid 2px #dee2e6' }}>
                <GiftTd>은</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.005</GiftTd>
                <GiftTd>25$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>
                  2, 4, 6, 8, 10, 12월
                  <br />
                  <br /> 최종 거래일
                </GiftTd>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
            <thead>
              <tr style={{ fontSize: '20px', borderBottom: '2px solid #dee2e6', borderTop: '2px solid #dee2e6' }}>
                <GiftTh colSpan={7}>에너지 상품</GiftTh>
              </tr>
              <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
                <GiftTh>대상품목</GiftTh>
                <GiftTh>매매시간</GiftTh>
                <GiftTh>틱단위</GiftTh>
                <GiftTh>틱가치</GiftTh>
                <GiftTh>강제청산</GiftTh>
                <GiftTh>만기일</GiftTh>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>크루드 오일</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.01</GiftTd>
                <GiftTd>10$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>매달 최종 거래일</GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6' }}>
                <GiftTd>천연 가스</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.001</GiftTd>
                <GiftTd>10$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>매달 최종 거래일</GiftTd>
              </tr>
              <tr style={{ height: '50px', borderTop: 'solid 2px #dee2e6', borderBottom: 'solid 2px #dee2e6' }}>
                <GiftTd>ICE 브랜드 유</GiftTd>
                <GiftTd>09:00 ~ 05:00(익일)</GiftTd>
                <GiftTd>0.1</GiftTd>
                <GiftTd>10$</GiftTd>
                <GiftTd>05:00(익일)</GiftTd>
                <GiftTd>매달 최종 거래일</GiftTd>
              </tr>
            </tbody>
          </Table>
        </CompanyIntroductionContent>
      </CompanyIntroductionWrap>
    </div>
  )
}
export default ForeignGift

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  overflow: auto;
  min-height: 2150px;
  border-radius: 16px;
  background: white;
  padding: 0 40px;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`
export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  vertical-align: middle;
  font-weight: bolder;
`
