import React from 'react'
import styled from 'styled-components'

const MoneyGuidePage = () => {
  return (
    <MoneyGuidePageWrap>
      <MoneyGuidePageInnerWrap>
        <MoneyGuidePageHeader>
          <MoneyGuidePageH3>입출금 가이드</MoneyGuidePageH3>
        </MoneyGuidePageHeader>
        {/* <MoneyGuidePageInnerTitle>입출금 안내</MoneyGuidePageInnerTitle> */}
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-1.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-2.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-3.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-4.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-5.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-6.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-7.png`} alt="" />
        <MoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-8.png`} alt="" />
      </MoneyGuidePageInnerWrap>
    </MoneyGuidePageWrap>
  )
}

export default MoneyGuidePage

const MoneyGuidePageWrap = styled.div`
  width: 100%;
  background: #f8f8f6;
  padding: 80px 0;
`
const MoneyGuidePageHeader = styled.div`
  position: relative;

  //   border: 1px solid red;
`
const MoneyGuidePageH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

const MoneyGuidePageInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  min-height: 4945px;

  border-radius: 16px;
  background: white;

  padding: 10px 40px;

  display: flex;
  flex-direction: column;

  gap: 25px;
`

const MoneyGuidePageImg = styled.img`
  width: 100%;
  height: 100%;
`

const MoneyGuidePageInnerTitle = styled.div`
  width: 100%;
  font-size: 22px;
  line-height: 25px;
  color: black;
`
