import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Layout from '../containers/Layout/Layout'
import LoginPage from '../containers/Login/LoginPage'
import MainPage from '../containers/MainPage/MainPage'
import NotFound404 from '../containers/NotFound/NotFound404'
import CompanyIntroduction from '../containers/Introduction/CompanyIntroduction'
import AssetSafe from '../containers/Introduction/AssetSafe'
import DomesticGift from '../containers/trading/DomesticGift'
import ForeignGift from '../containers/trading/ForeignGift'
import StockProduct from '../containers/trading/StockProduct'
import MoneyProduct from '../containers/trading/MoneyProduct'
import Economic from '../containers/trading/Economic'
import TradingRegulation from '../containers/TradingRegulation/TradingRegulation'
import SignUpPage from '../containers/SignUp/SignUpPage'
import PCDownload from '../containers/Download/PCDownload'
import MobileDownload from '../containers/Download/MobileDownload'
import PartnerShip from '../containers/PartnerShip/PartnerShip'
import Promotion from '../containers/Promotion/Promotion'
import Monitoring from '../containers/UseQuestion/Monitoring'
import CustomCenter from '../containers/UseQuestion/CustomCenter'
import MobileCompanyIntroduction from '../containers/Introduction/MobileCompanyIntroduction'
import MobileAssetSafe from '../containers/Introduction/MobileAssetSafe'
import MobileDomesticGift from '../containers/trading/MobileDomesticGift'
import MobileForeignGift from '../containers/trading/MobileForeignGift'
import MobileStockProduct from '../containers/trading/MobileStockProduct'
import MobileMoneyProduct from '../containers/trading/MobileMoneyProduct'
import MobileEconomic from '../containers/trading/MobileEconomic'
import MobileTradingRegulation from '../containers/TradingRegulation/MobileTradingRegulation'
import MobilePartnerShip from '../containers/PartnerShip/MobilePartnerShip'
import MobilePromotion from '../containers/Promotion/MobilePromotion'
import MobileMonitoring from '../containers/UseQuestion/MobileMonitoring'
import MobileCustomCenter from '../containers/UseQuestion/MobileCustomCenter'
import LoginFindPage from '../containers/Login/LoginFindPage'
import MobileLoginPage from '../containers/Login/MobileLoginPage'
import MobileLoginFindPage from '../containers/Login/MobileLoginFindPage'
import MobileSignUpPage from '../containers/SignUp/MobileSignUpPage'
import UserGuidePage from '../containers/guide/UserGuidePage'
import MoneyGuidePage from '../containers/guide/MoneyGuidePage'
import MobileUserGuidePage from '../containers/guide/MobileUserGuidePage'
import MobileMoneyGuidePage from '../containers/guide/MobileMoneyGuidePage'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  // if (!isLogin()) {
  //   return <Navigate to="/" replace state={{ alertMessage }} />
  // }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const Router = () => {
  return (
    <AnimatePresence>
      <Routes>
        {/* 공통으로 들어갈 컴포넌트 들 */}
        <Route element={<Layout />}>
          {/* 공통으로 들어갈 컴포넌트 들 */}
          {/* 메인페이지 */}
          <Route index element={<MainPage />} />
          {/* 그외 페이지 */}
          <Route element={<PrivateRoute />}>
            {/* <Route path="/money/compexchange" element={<CompExchangeForm />} /> */}
            {/* <Route path="/introduction/company" element={<CompanyIntroduction />} /> */}
            <Route path="/introduction/assetsafe" element={<AssetSafe />} />
            {/* <Route path="/trading/domesticgift" element={<DomesticGift />} /> */}
            <Route path="/trading/foreigngift" element={<ForeignGift />} />
            <Route path="/trading/stockproduct" element={<StockProduct />} />
            <Route path="/trading/moneyproduct" element={<MoneyProduct />} />
            <Route path="/trading/economic" element={<Economic />} />
            <Route path="/trading/regulation" element={<TradingRegulation />} />
            <Route path="/download/pc" element={<PCDownload />} />
            <Route path="/partnership" element={<PartnerShip />} />
            {/* <Route path="/promotion" element={<Promotion />} /> */}
            <Route path="/monitoring" element={<Monitoring />} />
            <Route path="/customcenter" element={<CustomCenter />} />
            <Route path="/information/guide" element={<UserGuidePage />} />
            <Route path="/information/money-guide" element={<MoneyGuidePage />} />

            {/* <Route path="/signup" element={<SignUpPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login-find" element={<LoginFindPage />} /> */}
            {/* <Route path="/mobile/introduction/company" element={<MobileCompanyIntroduction />} /> */}
            <Route path="/mobile/introduction/assetsafe" element={<MobileAssetSafe />} />
            {/* <Route path="/mobile/trading/domesticgift" element={<MobileDomesticGift />} /> */}
            <Route path="/mobile/trading/foreigngift" element={<MobileForeignGift />} />
            <Route path="/mobile/trading/stockproduct" element={<MobileStockProduct />} />
            <Route path="/mobile/trading/moneyproduct" element={<MobileMoneyProduct />} />
            <Route path="/mobile/trading/economic" element={<MobileEconomic />} />
            <Route path="/download/mobile" element={<MobileDownload />} />
            <Route path="/mobile/trading/regulation" element={<MobileTradingRegulation />} />
            <Route path="/mobile/partnership" element={<MobilePartnerShip />} />
            {/* <Route path="/mobile/promotion" element={<MobilePromotion />} /> */}
            <Route path="/mobile/monitoring" element={<MobileMonitoring />} />
            <Route path="/mobile/customcenter" element={<MobileCustomCenter />} />
            <Route path="/mobile/information/guide" element={<MobileUserGuidePage />} />
            <Route path="/mobile/information/money-guide" element={<MobileMoneyGuidePage />} />
            {/* <Route path="/mobile/signup" element={<MobileSignUpPage />} />
            <Route path="/mobile/login" element={<MobileLoginPage />} />
            <Route path="/mobile/login-find" element={<MobileLoginFindPage />} /> */}
          </Route>
        </Route>
        {/* 공통 Layout 이 안보여야 하는 컴포넌트 들 */}
        {/* 아래는 페이지 not found  */}

        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default Router
