import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const MobileCustomCenter = () => {
  return (
    <div
      style={{
        width: '100%',
        background: '#f8f8f6',
        padding: '12px',
      }}
    >
      <CompanyIntroductionWrap>
        <CompanyIntroductionHeader>
          <CompanyIntroductionH3>24시간 상담센터</CompanyIntroductionH3>
          <CompanyIntroductionP style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            토요일, 일요일 및 특정 공휴일에는 상담서비스를 제공하지 않습니다.
          </CompanyIntroductionP>
        </CompanyIntroductionHeader>
        <CompanyIntroductionContent>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div
              style={{
                border: '1px solid rgba(207, 207, 207)',
                display: 'inline-block',
                width: '100%',
                height: '233px',
                textAlign: 'center',
                marginRight: '10px',
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/img/main/cus1.jpg`} alt="" />
              <br />
              고객센터번호 <br />
              <br />
              <span style={{ fontWeight: '900', fontSize: '20px' }}>HTS 별도 기재</span>
            </div>
            <div
              style={{
                border: '1px solid rgba(207, 207, 207)',
                display: 'inline-block',
                width: '100%',
                height: '233px',
                textAlign: 'center',
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/img/main/cus2.jpg`} alt="" />
              <br />
              카카오톡 상담 <br />
              <br />
              <span style={{ fontWeight: '900', fontSize: '20px' }}>HTS 별도 기재</span>
            </div>
          </div>
        </CompanyIntroductionContent>
      </CompanyIntroductionWrap>
    </div>
  )
}
export default MobileCustomCenter

export const CompanyIntroductionWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  min-height: 650px;
  border-radius: 16px;
  background: white;
  padding: 0 10px;
`

export const CompanyIntroductionImg = styled.img`
  // width: 1080px;
  width: 100%;
  height: 518px;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
