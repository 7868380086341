import React from 'react'
import styled from 'styled-components'
import { FaArrowUp } from 'react-icons/fa'
import { colorScrollButtonBackground, colorScrollButtonBorder, colorScrollButtonHover } from '../../utils/palette'

const ScrollButton = () => {
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <ScrollButtonWrap>
      <SmothScroll>
        <SmothScrollContent onClick={scrollToTop}>
          <FaArrowUp />
        </SmothScrollContent>
      </SmothScroll>
    </ScrollButtonWrap>
  )
}

export default ScrollButton

const ScrollButtonWrap = styled.div``

const SmothScroll = styled.ul`
  position: fixed;
  bottom: 25px;
  right: 30px;
  list-style: none;
  z-index: 105;
`

const SmothScrollContent = styled.li`
  background: ${colorScrollButtonBackground};
  width: 50px;
  height: 50px;
  // line-height: 30px;
  // border-radius: 2px;
  display: block;
  text-align: center;
  color: rgba(255, 192, 26, 1);

  // background-size: 200%, 1px;
  // background-position: 0;
  border-radius: 50%;
  border: 2px solid ${colorScrollButtonBorder};

  &:hover {
    background: ${colorScrollButtonHover};
    color: rgba(255, 192, 26, 1);
  }

  svg {
    width: 25px !important;
    height: 45px !important;
  }
`
