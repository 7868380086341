import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const AssetSafe = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>자금안전</CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionP>
          <CompanyIntroductionImg src={`${process.env.PUBLIC_URL}/img/main/safe1.jpg`} alt="" />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>
              자금의 <span style={{ color: 'red' }}>안전성</span>
            </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          보호법인은 각 금융사와 소비자보호부에 다년간 보장을 받은 기업으로 사고시 금융 보호법에 따라 100% 보장을
          규정하고 있습니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>고객의 자금</span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          대영은 오직 고객분들의 자금만을 위한 보호법인 계좌를 보유하고 있으며, 고객님의 자금은 회사의 자금과 완전히
          분리되어있습니다. 고객님의 계좌는 안전하게 관리되며, 계좌 수익 및 입출금을 목적으로만 사용됩니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            <span style={{ fontSize: '14pt', fontWeight: 'bolder', marginLeft: '5px' }}>
              보상기금 <span style={{ color: 'blue' }}>보호</span>
            </span>
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ lineHeight: '25px', fontWeight: '400' }}>
          고객님께 최상의 전정성을 드리려고 노력하고 있습니다. 대영은 보호법인 기금의 회원으로서 금융 의무를 이행하지
          않을시 고객분들은 자금 보장을 받을 수 있습니다.
        </CompanyIntroductionP>
      </CompanyIntroductionContent>
    </CompanyIntroductionWrap>
  )
}
export default AssetSafe

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  // padding: 0 0 0 20px;

  // display: flex;
  // align-items: center;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`
export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const CompanyIntroductionImg = styled.img`
  width: 100%;
  height: 371px;
`
