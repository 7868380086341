import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const PartnerShip = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <br />
          파트너쉽
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>
          전문 트레이딩 파트너(Professional Trading Partner)
        </CompanyIntroductionH4>
        <CompanyIntroductionP>
          대영 트레이드는 최고의 거래 플랫폼을 제공하는것에 그치지 않고 다년간 주식은 물론 선물 거래를 통한 노하우로
          트레이더의 성공 투자를 도울 전문 트레이딩 파트너를 소개함으로써 <br />
          자신에게 맞는 파트너를 직접 선택하고 그것을 바탕으로 트레이더 스스로 자립할 수 있는 환경을 만들어 드립니다.
        </CompanyIntroductionP>
        <div
          style={{
            marginTop: '30px',
            marginLeft: '50px',
            width: '250px',
            height: '900px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            padding: '20px',
            border: '1px solid #cfcfcf',
            display: 'inline-block',
          }}
        >
          <CompanyIntroductionH6 style={{ background: 'red' }}>Partner① FreeCap 전문가</CompanyIntroductionH6>
          <CompanyIntroductionP style={{ lineHeight: '20px', textAlign: 'left' }}>
            · KOSPI200 선물 0.0012%
            <br />
            <br />
            · KOSPI200 옵션 0.10%
            <br />
            <br />
            · CME 야간선물 0.0014%
            <br />
            <br />
            · EUREX 야간옵션 0.12%
            <br />
            <br />
            · 해외선물(기본) 3.5$
            <br />
            <br />
            · Gold 3.5$
            <br />
            <br />
            · Crude Oil 3.5$
            <br />
            <br />
            · Hangseng 3.5$
            <br />
            <br />
            · Nasdaq100 3.5$
            <br />
            <br />
            · S&P500 3.5$
            <br />
            <br />
            · EuroFX 3.5$
            <br />
            <br />
            · Natural Gas 3.5$
            <br />
            <br />
            · Mini Dow 3.5$
            <br />
            <br />
            · 암호화폐 3.5$
            <br />
            <br />
            · 종목 : 해선전종목,국선/항셍
            <br />
            <br />
            · 교육 방식 : 1:1맞춤교육
            <br />
            <br />
            · 미팅,대강연회,매주화요일
            <br />
            <br />
            · FreeCap내 특별교육방송
            <br />
            <br />
            ·문의 : ☎010-2397-1587
            <br />
            <br />· 교육 센터 : FreeCap
          </CompanyIntroductionP>
        </div>
        <div
          style={{
            marginTop: '30px',
            width: '250px',
            height: '900px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            padding: '20px',
            border: '1px solid #cfcfcf',
            display: 'inline-block',
            marginLeft: '50px',
          }}
        >
          <CompanyIntroductionH6 style={{ background: 'blue' }}>Partner②</CompanyIntroductionH6>
          <CompanyIntroductionP style={{ lineHeight: '20px', textAlign: 'left' }}>
            · KOSPI200 선물 0.0016%
            <br />
            <br />
            · KOSPI200 옵션 0.1%
            <br />
            <br />
            · CME 야간선물 0.0018%
            <br />
            <br />
            · EUREX 야간옵션 0.12%
            <br />
            <br />
            · 해외선물(기본) ?$
            <br />
            <br />
            · Gold ?$
            <br />
            <br />
            · Crude Oil ?$
            <br />
            <br />
            · Hangseng ?$
            <br />
            <br />
            · Nasdaq100 ?$
            <br />
            <br />
            · S&P500 ?$
            <br />
            <br />
            · EuroFX ?$
            <br />
            <br />
            · Natural Gas ?$
            <br />
            <br />
            · Mini Dow ?$
            <br />
            <br />
            · 암호화폐 ?$
            <br />
            <br />
            · 교육 종목 :
            <br />
            <br />
            · 교육 방식 :
            <br />
            <br />
            · 문의 :
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </CompanyIntroductionP>
        </div>
        <div
          style={{
            marginTop: '30px',
            width: '250px',
            height: '900px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            padding: '20px',
            border: '1px solid #cfcfcf',
            display: 'inline-block',
            marginLeft: '50px',
          }}
        >
          <CompanyIntroductionH6 style={{ background: 'red' }}>Partner③</CompanyIntroductionH6>
          <CompanyIntroductionP style={{ lineHeight: '20px', textAlign: 'left' }}>
            · KOSPI200 선물 0.0016%
            <br />
            <br />
            · KOSPI200 옵션 0.1%
            <br />
            <br />
            · CME 야간선물 0.0018%
            <br />
            <br />
            · EUREX 야간옵션 0.12%
            <br />
            <br />
            · 해외선물(기본) ?$
            <br />
            <br />
            · Gold ?$
            <br />
            <br />
            · Crude Oil ?$
            <br />
            <br />
            · Hangseng ?$
            <br />
            <br />
            · Nasdaq100 ?$
            <br />
            <br />
            · S&P500 ?$
            <br />
            <br />
            · EuroFX ?$
            <br />
            <br />
            · Natural Gas ?$
            <br />
            <br />
            · Mini Dow ?$
            <br />
            <br />
            · 암호화폐 ?$
            <br />
            <br />
            · 교육 종목 :
            <br />
            <br />
            ·교육 방식 :
            <br />
            <br />
            · 문의 :
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </CompanyIntroductionP>
        </div>
        <div
          style={{
            marginTop: '30px',
            width: '250px',
            height: '900px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            padding: '20px',
            border: '1px solid #cfcfcf',
            display: 'inline-block',
            marginLeft: '50px',
          }}
        >
          <CompanyIntroductionH6 style={{ background: 'blue' }}>Partner④</CompanyIntroductionH6>
          <CompanyIntroductionP style={{ lineHeight: '20px', textAlign: 'left' }}>
            · KOSPI200 선물 0.0016%
            <br />
            <br />
            · KOSPI200 옵션 0.1%
            <br />
            <br />
            · CME 야간선물 0.0018%
            <br />
            <br />
            · EUREX 야간옵션 0.12%
            <br />
            <br />
            · 해외선물(기본) ?$
            <br />
            <br />
            · Gold ?$
            <br />
            <br />
            · Crude Oil ?$
            <br />
            <br />
            · Hangseng ?$
            <br />
            <br />
            · Nasdaq100 ?$
            <br />
            <br />
            · S&P500 ?$
            <br />
            <br />
            · EuroFX ?$
            <br />
            <br />
            · Natural Gas ?$
            <br />
            <br />
            · Mini Dow ?$
            <br />
            <br />
            · 암호화폐 ?$
            <br />
            <br />
            · 교육 종목 :
            <br />
            <br />
            ·교육 방식 :
            <br />
            <br />
            · 문의 :
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </CompanyIntroductionP>
        </div>

        {/* <div
          style={{
            display: 'inline-block',
            marginLeft: '50px',
            width: '250px',
            height: '800px',
            background: '#f3f3f3',
            textAlign: 'left',
            verticalAlign: 'left',
            padding: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionH6 style={{ background: 'blue' }}>Partner②</CompanyIntroductionH6>
          <CompanyIntroductionP style={{ lineHeight: '20px', textAlign: 'left' }}>
            · KOSPI200 선물 0.0016%
            <br />
            <br />
            · KOSPI200 옵션 0.1%
            <br />
            <br />
            · CME 야간선물 0.0018%
            <br />
            <br />
            · EUREX 야간옵션 0.12%
            <br />
            <br />
            · 해외선물(기본) ?$
            <br />
            <br />
            · Gold ?$
            <br />
            <br />
            · Crude Oil ?$
            <br />
            <br />
            · Hangseng ?$
            <br />
            <br />
            · Nasdaq100 ?$
            <br />
            <br />
            · S&P500 ?$
            <br />
            <br />
            · EuroFX ?$
            <br />
            <br />
            · Natural Gas ?$
            <br />
            <br />
            · Mini Dow ?$
            <br />
            <br />
            · 암호화폐 ?$
            <br />
            <br />
            · 교육 종목 :
            <br />
            <br />
            · 교육 방식 :
            <br />
            <br />· 문의 :
          </CompanyIntroductionP>
        </div> */}
      </CompanyIntroductionContent>
    </CompanyIntroductionWrap>
  )
}
export default PartnerShip

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  // padding: 0 0 0 20px;

  // display: flex;
  // align-items: center;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH6 = styled.h6`
  padding: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
