import React from 'react'
import styled from 'styled-components'

const MainContent2 = () => {
  return (
    <MainContent2Wrap>
      <MainContent2InnerWrap>
        <MainContent2TitleWrap>
          <MainContent2Title>대영만의 제공</MainContent2Title>
        </MainContent2TitleWrap>
        <MainContent2Content>
          <MainContent2ContentImg src={`${process.env.PUBLIC_URL}/img/main/main05.png`} />
          <MainContent2ContentInner>
            <MainContent2ContentTitle>해외 선물상품 거래</MainContent2ContentTitle>
            <MainContent2ContentContent>
              {/* A few clicks is all it takes to get into the next moon shot */}
            </MainContent2ContentContent>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                몇 번의 클릭으로 해외 주요 선물상품의 빠른 거래 체결
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                NASDAQ, S&P500 등 세계 주요 지수 상품의 거래 가능
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                100,000건 이상의 TPS 제공
              </li>
            </ul>
          </MainContent2ContentInner>
        </MainContent2Content>
        <MainContent2Content>
          <MainContent2ContentImg src={`${process.env.PUBLIC_URL}/img/main/main06.png`} />
          <MainContent2ContentInner>
            <MainContent2ContentTitle>TRADING</MainContent2ContentTitle>
            <MainContent2ContentContent>{/* 암호화폐 거래로 수익을 더 많이 얻으세요. */}</MainContent2ContentContent>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                동종 업계 최저 수수료
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                최고수준의 UI/UX를 포함한 최적의 거래환경
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                고도화 된 트레이딩 툴
              </li>
            </ul>
          </MainContent2ContentInner>
        </MainContent2Content>
        <MainContent2Content>
          <MainContent2ContentImg src={`${process.env.PUBLIC_URL}/img/main/main07.png`} />
          <MainContent2ContentInner>
            <MainContent2ContentTitle>거래편의성</MainContent2ContentTitle>
            <MainContent2ContentContent>{/* Up your trading game with 5x leverage */}</MainContent2ContentContent>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                전문 트레이더들에 의해 고안된 트레이딩 시스템
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                빠른 체결속도와 최소화 된 슬리피지
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/img/main/main-arrow-icon.png`} alt="" style={{ height: '16px' }} />
                웹 MTS적용으로 어떤 환경에서도 거래 가능
              </li>
            </ul>
          </MainContent2ContentInner>
        </MainContent2Content>
      </MainContent2InnerWrap>
    </MainContent2Wrap>
  )
}

export default MainContent2

const MainContent2Wrap = styled.div`
  width: 100%;
  min-height: 1480px;
  position: relative;

  background: #f8f8f6;

  padding: 30px 0;
`

const MainContent2InnerWrap = styled.div`
  width: 1260px;
  height: 1400px;
  margin: 0 auto;
  border-radius: 16px;
  background: white;

  display: flex;
  flex-direction: column;

  padding: 80px 117px;
`

const MainContent2TitleWrap = styled.div`
  display: block;
  color: #000;
  text-align: center;
`

const MainContent2Title = styled.p`
  font-size: 36px;
  line-height: 45.18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`

const MainContent2ContentImg = styled.img`
  vertical-align: middle;
  border-style: none;

  height: 338px;
  position: absolute;
  top: 0;
`

const MainContent2ContentTitle = styled.p`
  font-size: 30px;
  line-height: 37.65px;
  font-weight: 700;
  margin-bottom: 8px;
`

const MainContent2ContentContent = styled.p`
  font-size: 20px;
  line-height: 25.1px;
  font-weight: 500;
  margin-bottom: 24px;
`

const MainContent2ContentInner = styled.div`
  padding-top: 60px;

  ul li {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 18px;
    position: relative;
    padding-left: 24px;
  }

  ul li img {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const MainContent2Content = styled.div`
  margin-top: 80px;
  text-align: left;
  position: relative;
  height: 338px;
  color: #252831;

  &:nth-child(odd) {
    padding-right: 458px;
    ${MainContent2ContentImg} {
      right: 0;
    }
  }

  &:nth-child(2n) {
    padding-left: 458px;
    ${MainContent2ContentImg} {
      left: 0;
    }
  }
`
