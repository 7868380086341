import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaLock } from 'react-icons/fa'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { siginIn } from '../../api/auth/auth'
import { setUserId } from '../../utils/authority'

const LoginPage = () => {
  const navigate = useNavigate

  const [memberId, setMemberId] = useState('')
  const [memberPassword, setMemberPassword] = useState('')

  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      const body = {
        memberId,
        memberPassword,
      }

      siginIn(body)
        .then(res => {
          setUserId(memberId)
          window.location.href = '/'
        })
        .catch(error => {
          console.log('error => ', error)
          alert('아이디와 비밀번호를 다시 확인해주세요.')
          window.location.reload()
        })
    }

    setValidated(true)
  }

  return (
    <LoginPageWrap>
      <LoginPageInnerWrap>
        <LoginPageHeader>
          <LoginPageHeaderInfo>로그인</LoginPageHeaderInfo>
          <LoginPageHeaderLine />
        </LoginPageHeader>
        <LoginPageContentWrap>
          <LoginPageFormIconWrap>
            <LoginPageFormIcon color="gray" />
          </LoginPageFormIconWrap>
          <LoginPageFormWrap>
            <CustomForm noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3 mt-4">
                <InputGroup hasValidation>
                  <Form.Control
                    name="memberId"
                    type="text"
                    placeholder="회원 ID"
                    aria-describedby="inputGroupPrepend"
                    required
                    defaultValue={memberId}
                    onChange={e => setMemberId(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">아이디를 입력해주세요.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <InputGroup hasValidation>
                  <Form.Control
                    name="memberPassword"
                    type="password"
                    placeholder="비밀번호"
                    aria-describedby="inputGroupPrepend"
                    required
                    defaultValue={memberPassword}
                    onChange={e => setMemberPassword(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">비밀번호를 입력해주세요.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <LoginPageFormButton variant="primary" type="submit">
                로그인
              </LoginPageFormButton>
            </CustomForm>
          </LoginPageFormWrap>
          <LoginPageInfoWrap>
            <LoginPageInfo
              onClick={e => {
                window.location.href = '/login-find'
              }}
            >
              아이디 비밀번호 찾기
            </LoginPageInfo>
            <span>/</span>
            <LoginPageInfo
              onClick={e => {
                window.location.href = '/signup'
              }}
            >
              회원 가입
            </LoginPageInfo>
          </LoginPageInfoWrap>
        </LoginPageContentWrap>
      </LoginPageInnerWrap>
    </LoginPageWrap>
  )
}

export default LoginPage

const LoginPageWrap = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 136px;
`

const LoginPageInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 40px;
`

const LoginPageHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const LoginPageHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  color: #000;
`
const LoginPageHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

const LoginPageContentWrap = styled.div`
  width: 350px;

  margin: 0 auto;

  background: #f7f7f7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 40px 40px;

  border-radius: 2px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
`

const CustomForm = styled(Form)`
  width: 100%;
`

const LoginPageFormIconWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginPageFormIcon = styled(FaLock)`
  width: 70px;
  height: 80px;
`

const LoginPageFormWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginPageFormButton = styled(Button)`
  width: 100%;

  background-color: #1d409f;
`

const LoginPageInfoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;

  gap: 5px;
`
const LoginPageInfo = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000;

  &:hover {
    cursor: pointer;
  }
`
