import React from 'react'
import styled from 'styled-components'

const MobileFooter = () => {
  return (
    <FooterWrap>
      <FooterLogoBox>
        <div>
          <img alt="" src={`${process.env.PUBLIC_URL}/img/main/logo.png`} width="210px" height="auto" />
        </div>
        <p>
          <img src={`${process.env.PUBLIC_URL}/img/main/danger.png`} alt="" height="12px" />
          <span style={{ color: '#ff1100' }}>위험 고지</span> 대영은 최상의 서비스와 최고의 기술지원을 위해 투자합니다.
          따라서 큰 주가 변동에서도 안정된 체결과 주문 에러가 일어나지 않습니다. <br />
          다만 큰 유동성의 상품은 단점으로 작용될 수 있습니다. 거래를 결정하기 전, 본인의 투자 목표와 경험, 수반되는
          위험 수준을 충분히 고려하여 접근하셔야됩니다. <br /> 대영은 본인이 필요한 경우 독립적인 투자 상담 및 전문적인
          트레이딩을 무한 지원해드립니다.
          <br />
          Copyright © DAEYOUNG 2018. All rights reserved.
        </p>
      </FooterLogoBox>
    </FooterWrap>
  )
}

export default MobileFooter

const FooterWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: block;
  position: relative;
  // top: 260px;

  background: #505050;
  padding: 10px 0;
`

const FooterInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  padding: 0 150px;
`

const FooterMenuTitle = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: #fdaa12;
  margin-bottom: 8px;
`

const FooterMenuContent = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: #333;
  margin-bottom: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 20px;
  }

  &:hover {
    color: #fdaa12;
  }
`

const FooterMenu = styled.div`
  width: 160px;
`

const FootterIconBox = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  div {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
`

const FooterLogoBox = styled.div`
  width: 100%;
  // text-align: center;
  background: #505050;
  color: #fff;
  padding: 0 16px;

  p {
    margin-top: 15px;
    word-break: break-all;
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
  }
`
