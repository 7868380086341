import axios from 'axios'

// export const API_URL = 'http://localhost:6052'

// 서버
export const API_URL = ''

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
})

axiosInstance.interceptors.request.use(
  config => {
    // 요청 바로 직전
    // axios 설정값에 대해 작성합니다.

    config.headers.Accept = 'application/json'
    config.headers.ContentType = 'application/json'

    // config.headers.Authorization = `Bearer ${getAccessToken()}`

    return config
  },
  error => {
    // 요청 에러 처리를 작성합니다.
    return Promise.reject(error)
  },
)

/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
axiosInstance.interceptors.response.use(
  response => {
    /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다. 
        .then() 으로 이어집니다.
    */
    return response
  },

  error => {
    return Promise.reject(error)
  },
)

export default axiosInstance
