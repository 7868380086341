import React from 'react'
import styled from 'styled-components'
import { TickerTape } from 'react-ts-tradingview-widgets'

const MainTradingView = () => {
  return (
    <TradingviewWidgetWrap>
      <TradingviewTickerTape
        copyrightStyles={{
          parent: {
            display: 'none',
          },
        }}
        colorTheme="light"
        showSymbolLogo={false}
        displayMode="regular"
        symbols={[
          {
            proName: 'FOREXCOM:SPXUSD',
            title: 'S&P 500',
          },
          {
            proName: 'FOREXCOM:NSXUSD',
            title: 'Nasdaq 100',
          },
          {
            proName: 'FX_IDC:EURUSD',
            title: 'EUR/USD',
          },
          {
            proName: 'BITSTAMP:BTCUSD',
            title: 'BTC/USD',
          },
          {
            proName: 'BITSTAMP:ETHUSD',
            title: 'ETH/USD',
          },
        ]}
      />
    </TradingviewWidgetWrap>
  )
}

export default MainTradingView

// 트레이딩 뷰 한줄짜리
const TradingviewWidgetWrap = styled.div`
  // width: 105%;
  // width: calc(100% + 20px);
`

const TradingviewTickerTape = styled(TickerTape)``
