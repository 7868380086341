import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'
import { EconomicCalendar } from 'react-ts-tradingview-widgets'

const Economic = () => {
  return (
    <div
      style={{
        width: '100%',
        background: '#f8f8f6',
        padding: '80px 0',
        minHeight: '1000px',
      }}
    >
      <CompanyIntroductionWrap>
        <CompanyIntroductionHeader>
          <CompanyIntroductionH3>경제캘린더</CompanyIntroductionH3>
        </CompanyIntroductionHeader>
        <CompanyIntroductionContent>
          <EconomicCalendar
            colorTheme="light"
            height={800}
            width="100%"
            locale="kr"
            copyrightStyles={{
              parent: {
                display: 'none',
              },
            }}
            // importanceFilter="0,1"
          />
        </CompanyIntroductionContent>
      </CompanyIntroductionWrap>
    </div>
  )
}
export default Economic

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  min-height: 950px;

  border-radius: 16px;
  background: white;

  padding: 0 40px;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`
export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  vertical-align: middle;
  font-weight: bolder;
`
