import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { isLogin } from '../../utils/authority'

const MainContent = () => {
  return (
    <MainContentWrap>
      <MainContentInnerWrap>
        <MainContentHeader>
          <MainContentHeaderInfo>3가지 간편한 절차</MainContentHeaderInfo>
          <MainContentHeaderLine />
        </MainContentHeader>
        <MainContentInnerContentWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_join_1.jpg`} />
            <MainContentInnerContentImgHeader>등록</MainContentInnerContentImgHeader>
            <MainContentInnerContentImgInfo>우측상단에서 회원가입을 하세요</MainContentInnerContentImgInfo>
          </MainContentInnerContentImgWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_join_2.jpg`} />
            <MainContentInnerContentImgHeader>확인</MainContentInnerContentImgHeader>
            <MainContentInnerContentImgInfo>계정 승인에 필요한 인증을 거치게 됩니다</MainContentInnerContentImgInfo>
          </MainContentInnerContentImgWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_join_3.jpg`} />
            <MainContentInnerContentImgHeader>거래</MainContentInnerContentImgHeader>
            <MainContentInnerContentImgInfo>
              가장 전문적인 대영 트레이더스와 거래를 시작하세요
            </MainContentInnerContentImgInfo>
          </MainContentInnerContentImgWrap>
        </MainContentInnerContentWrap>
        {/* <MainContentInnerContentButtonWrap>
          <MainContentInnerContentButton
            variant="danger"
            onClick={e => {
              if (isLogin()) {
                return
              }
              window.location.href = '/signup'
            }}
          >
            지금 시작하세요
          </MainContentInnerContentButton>
        </MainContentInnerContentButtonWrap> */}
      </MainContentInnerWrap>
    </MainContentWrap>
  )
}

export default MainContent

const MainContentWrap = styled.div`
  width: 100%;
  position: relative;
`

const MainContentInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  padding-bottom: 30px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const MainContentHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const MainContentHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  text-align: center;

  color: #000;
`
const MainContentHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

const MainContentInnerContentWrap = styled.div`
  width: 100%;

  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`

const MainContentInnerContentImgWrap = styled.div`
  width: calc(100% / 3);
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 15px;
`

const MainContentInnerContentImg = styled.img`
  width: 130px;
  height: 130px;

  border-radius: 50%;
`

const MainContentInnerContentImgHeader = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #000;
`

const MainContentInnerContentImgInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000;
`

const MainContentInnerContentButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  margin: 40px 0;
`

const MainContentInnerContentButton = styled(Button)`
  width: max-content;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`
