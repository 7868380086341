import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const MainContentTwo = () => {
  return (
    <MainContentWrap>
      <MainContentInnerWrap>
        <MainContentHeader>
          <MainContentHeaderInfo>수상 및 표창</MainContentHeaderInfo>
          <MainContentHeaderLine />
        </MainContentHeader>
        <MainContentInnerContentWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_aw_1.jpg`} />
            <MainContentInnerContentImgHeader>베스트 Forex 고객 서비스</MainContentInnerContentImgHeader>
          </MainContentInnerContentImgWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_aw_2.jpg`} />
            <MainContentInnerContentImgHeader>2018 베스트 유럽 베스트 FX 브로커</MainContentInnerContentImgHeader>
          </MainContentInnerContentImgWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_aw_3.jpg`} />
            <MainContentInnerContentImgHeader>베스트 Forex 체결 브로커상</MainContentInnerContentImgHeader>
          </MainContentInnerContentImgWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_aw_4.jpg`} />
            <MainContentInnerContentImgHeader>2017 베스트 FX 서비스 제공 업체상</MainContentInnerContentImgHeader>
          </MainContentInnerContentImgWrap>
          <MainContentInnerContentImgWrap>
            <MainContentInnerContentImg src={`${process.env.PUBLIC_URL}/img/main/circle_aw_5.jpg`} />
            <MainContentInnerContentImgHeader>World Finance 100</MainContentInnerContentImgHeader>
          </MainContentInnerContentImgWrap>
        </MainContentInnerContentWrap>
      </MainContentInnerWrap>
    </MainContentWrap>
  )
}

export default MainContentTwo

const MainContentWrap = styled.div`
  width: 100%;
  position: relative;
`

const MainContentInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`
const MainContentHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const MainContentHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  text-align: center;

  color: #000;
`
const MainContentHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

const MainContentInnerContentWrap = styled.div`
  width: 100%;

  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const MainContentInnerContentImgWrap = styled.div`
  width: calc(100% / 3);
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 10px;

  padding: 0 15px;
`

const MainContentInnerContentImg = styled.img`
  width: 130px;
  height: 130px;
`

const MainContentInnerContentImgHeader = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #000;
`
