import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const MobileDomesticGift = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <img src="https://daol1234.kr/img/p_01.jpg" width={120} height={120} alt="" />
          <br />
          국내선물
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '60px' }}>
          <thead>
            <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
              <GiftTh>대상품목</GiftTh>
              <GiftTh>매매시간</GiftTh>
              <GiftTh>틱단위</GiftTh>
              <GiftTh>틱가치</GiftTh>
              <GiftTh>수수료</GiftTh>
              <GiftTh>강제청산</GiftTh>
              <GiftTh>만기일</GiftTh>
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>
                KOSPI 지수주간선물
                <br />
                (최근 월물)
              </GiftTd>
              <GiftTd>
                <span style={{ color: 'blue', fontWeight: '500' }}>정상일</span> AM 09:00 ~ PM 15:34
                <br />
                <br />
                <span style={{ color: 'red', fontWeight: '500' }}>만기일</span> AM 09:00 ~ PM 15:15
              </GiftTd>
              <GiftTd>0.05</GiftTd>
              <GiftTd>12,500원</GiftTd>
              <GiftTd>편도 0.0016%</GiftTd>
              <GiftTd>
                PM 15:34 시장가 반대매매 청산
                <br />
                <br />
                만기일 15:15 시장가 반대매매 청산
              </GiftTd>
              <GiftTd>
                3,6,9,12월
                <br /> 둘째주 목요일
              </GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>
                KOSPI 지수야간선물
                <br />
                (최근 월물)
              </GiftTd>
              <GiftTd>PM 18:00 ~ 익일 04:55</GiftTd>
              <GiftTd>0.05</GiftTd>
              <GiftTd>12,500원</GiftTd>
              <GiftTd>편도 0.0018%</GiftTd>
              <GiftTd>익일 04:55 시장가 반대매매 청산</GiftTd>
              <GiftTd>없음</GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>
                KOSPI 지수주간옵션
                <br />
                (당월물)
              </GiftTd>
              <GiftTd>
                <span style={{ color: 'blue', fontWeight: '500' }}>정상일</span> AM 09:00 ~ PM 15:34
                <br />
                <br />
                <span style={{ color: 'red', fontWeight: '500' }}>만기일</span> AM 09:00 ~ PM 15:15
              </GiftTd>
              <GiftTd>
                0.01
                <br />
                <span style={{ fontSize: '10pt' }}>(10.0이상은 틱당 0.05)</span>
              </GiftTd>
              <GiftTd>
                2,500원
                <br />
                <span style={{ fontSize: '10pt' }}>(10.0 이상은 틱당 12,500원)</span>
              </GiftTd>
              <GiftTd>편도 0.10%</GiftTd>
              <GiftTd>
                PM 15:34 시장가 반대매매 청산
                <br />
                <br />
                만기일 15:15 시장가 반대매매 청산
              </GiftTd>
              <GiftTd>
                매월 둘째주 <br /> 목요일
              </GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6', borderBottom: 'solid 2px #dee2e6' }}>
              <GiftTd>
                KOSPI 야간지수옵션
                <br />
                (당월물)
              </GiftTd>
              <GiftTd>
                <span style={{ color: 'blue', fontWeight: '500' }}>정상일</span> AM 09:00 ~ PM 15:34
                <br />
                <br />
                <span style={{ color: 'red', fontWeight: '500' }}>만기일</span> AM 09:00 ~ PM 15:15
              </GiftTd>
              <GiftTd>0.05</GiftTd>
              <GiftTd>
                2,500원
                <br />
                <span style={{ fontSize: '10pt' }}>(10.0 이상은 틱당 12,500원)</span>
              </GiftTd>
              <GiftTd>편도 0.12%</GiftTd>
              <GiftTd>
                익일 05:00 시장가 반대매매 청산
                <br />
                <br />
                서머타임 : 익일 04:00 시장가 반대매매 청산
              </GiftTd>
              <GiftTd>없음</GiftTd>
            </tr>
          </tbody>
        </Table>
      </CompanyIntroductionContent>
    </CompanyIntroductionWrap>
  )
}
export default MobileDomesticGift

export const CompanyIntroductionWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  // right: 440px;
  top: 15px;
`
export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  vertical-align: middle;
  font-weight: bolder;
`
