import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const TradingRegulation = () => {
  return (
    <div
      style={{
        width: '100%',
        background: '#f8f8f6',
        padding: '80px 0',
        minHeight: '1000px',
      }}
    >
      <CompanyIntroductionWrap>
        <CompanyIntroductionHeader>
          <CompanyIntroductionH3>거래 규정</CompanyIntroductionH3>
        </CompanyIntroductionHeader>
        <CompanyIntroductionContent>
          <CompanyIntroductionH4 style={{ marginTop: '60px' }}>매매규정</CompanyIntroductionH4>
          <CompanyIntroductionP style={{ marginBottom: '-50px', lineHeight: '30px' }}>
            대영은 거래소에서 정하는 매매규정을 준수하여 어떠한 경우에도 트레이더에게 불리한 상황이 발생되지 않도록
            불필요한 소프트웨어의 사용을 일절 하지 않으므로써 트레이더와의 신뢰관계를 더욱 단단히 하는데 가장 많은
            노력과 시간을 들이고 있습니다.
          </CompanyIntroductionP>
          <CompanyIntroductionH4 style={{ marginTop: '100px' }}>대영의 체결 규정</CompanyIntroductionH4>
          <CompanyIntroductionP>
            ※ 대영은 수천 개의 주문도 딜레이 현상 없이 일괄 체결이 가능한 우수한 체결력을 자랑합니다.
          </CompanyIntroductionP>
          <div
            style={{
              marginTop: '30px',
              height: '300px',
              background: '#f3f3f3',
              textAlign: 'left',
              verticalAlign: 'left',
              padding: '10px 20px',
              border: '1px solid #cfcfcf',
            }}
          >
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              지정가 주문 : 체결 시간에 상관없이 체결할 가격을 직접 지정하여 체결하는 주문방식 <br />※ 주문장·단점 :
              슬리피지 없이 지정한 호가에 체결이 가능하지만 대기 순번과 반대 대기 잔량에 따라 체결이 안될 수 있습니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              시장가 주문 : 체결 가격에 상관없이 주문 가능한 가격에 즉시 체결하는 주문방식
              <br />※ 주문장·단점 : 대기순번과 잔량을 무시하고 즉시 체결이 가능하지만 현재가에는 주문을 넣을수 없으며
              체결시 슬리피지 발생될 수 있습니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              스탑로스 주문(손절/익절) :청산 가격을 미리 정하여 현재가가 닿는 즉시 자동으로 반대매매 체결을 시키는
              주문방식
              <br />※ 주문장·단점 : 시장가 유형의 주문으로 호가가 닿는 즉시 빠른 체결이 가능하지만 슬리피지가 발생될 수
              있습니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              MIT 주문 : 진입과 청산, 장 상황에 따라 가격을 정해두고 예약 할 수 있는 주문 <br />※ 주문장·단점 : 시장가
              유형의 주문으로 호가가 닿는 즉시 빠른 체결이 가능하지만 슬리피지가 발생될 수 있습니다.
            </CompanyIntroductionP>
          </div>
          <div
            style={{
              marginTop: '30px',
              height: '200px',
              background: '#f3f3f3',
              textAlign: 'left',
              verticalAlign: 'left',
              padding: '10px 20px',
              border: '1px solid #cfcfcf',
            }}
          >
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 로스컷은 마이너스 담보금으로 인한 큰손실이 발생되는것을 막기 위한 주문 입니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 로스컷은 시장가 유형의 주문으로 최초 신호가와 체결가의 차이가 있을수 있습니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 로스컷은 1계약당 100,000원 부근에서 발동됩니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 로스컷은 100,000원 부근에서 청산 신호를 보내주지만 호가 변동에 따라 더 큰 손실을 볼 수 있습니다.
            </CompanyIntroductionP>
          </div>
          <div
            style={{
              marginTop: '30px',
              height: '200px',
              background: '#f3f3f3',
              textAlign: 'left',
              verticalAlign: 'left',
              padding: '10px 20px',
              border: '1px solid #cfcfcf',
            }}
          >
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 오버나잇은 초기 미신청 상태로 직접 신청을 해야합니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 오버나잇에 필요한 증거금은 계약당 1,000,000원 입니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 장마감 시간을 기준으로 한계약이라도 오버나잇 증거금이 부족할 경우 장마감 시간에 맞춰 보유 포지션은 일괄
              청산 됩니다.
            </CompanyIntroductionP>
            <CompanyIntroductionP style={{ lineHeight: '25px', textAlign: 'left' }}>
              · 종목 만기일에는 오버나잇이 불가능 합니다.
            </CompanyIntroductionP>
          </div>
        </CompanyIntroductionContent>
      </CompanyIntroductionWrap>
    </div>
  )
}
export default TradingRegulation

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  overflow: auto;
  position: relative;
  min-height: 1200px;
  border-radius: 16px;
  background: white;
  padding: 0 40px;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
