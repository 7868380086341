import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Table } from 'react-bootstrap'
import styled from 'styled-components'
import { FaExclamationCircle, FaRegEdit } from 'react-icons/fa'
import { siginUp } from '../../api/auth/auth'

const SignUpPage = () => {
  // 다음 으로 넘어가기 위한 플래그
  const [isProcess, setIsProcess] = useState(false)

  const [totalChecked, setTotalChecked] = useState(false)
  // 회원가입 약관
  const [signUpChecked, setSignUpChecked] = useState(false)

  // 개인정보 약관
  const [personalChecked, setPersonalChecked] = useState(false)

  // radioBox
  const [makeAccount, setMakeAccount] = useState('')

  const [memberId, setMemberId] = useState('')
  const [memberPassword, setMemberPassword] = useState('')
  const [memberPasswordConfirm, setMemberPasswordConfirm] = useState('')
  const [memberName, setMemberName] = useState('')
  const [memberNickName, setMemberNickName] = useState('')
  const [memberEmail, setMemberEmail] = useState('')
  const [memberPhoneNum, setMemberPhoneNum] = useState('')
  const [memberBankName, setMemberBankName] = useState('')
  const [memberAccountNum, setMemberAccountNum] = useState('')
  const [memberAccountHolder, setMemberAccountHolder] = useState('')
  const [smsReceived, setSmsReceived] = useState(false)
  const [recommendedId, setRecommendedId] = useState('')

  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      if (memberPassword !== memberPasswordConfirm) {
        alert('비밀번호가 일치하지 않습니다.')
        return
      }
      const body = {
        memberId,
        memberPassword,
        memberName,
        memberNickName,
        memberEmail,
        memberPhoneNum,
        memberBankName,
        memberAccountNum,
        memberAccountHolder,
        smsReceived,
        recommendedId: !recommendedId ? null : recommendedId,
      }

      console.log('body ::', body)

      siginUp(body)
        .then(res => {
          alert('환영합니다. 회원가입이 성공적으로 되었습니다.')
          window.location.href = '/'
        })
        .catch(error => {
          console.log('error => ', error)
          alert('회원가입에 실패했습니다. 다시 시도해주세요.')
          window.location.reload()
        })
    }

    setValidated(true)
  }

  return (
    <SignUpPageWrap>
      <SignUpPageInnerWrap>
        <SignUpPageHeader>
          <SignUpPageHeaderInfo>회원 가입</SignUpPageHeaderInfo>
          <SignUpPageHeaderLine />
        </SignUpPageHeader>

        {!isProcess ? (
          <SignUpPageTermsWrap>
            <SignUpPageTermsInfo>
              <SignUpPageTermsIcon />
              회원가입약관 및 개인정보처리방침안내의 내용에 동의하셔야 회원가입 하실 수 있습니다.
            </SignUpPageTermsInfo>
            <SignUpPageTermsCheckWrap>
              <input
                type="checkbox"
                checked={totalChecked}
                onChange={e => {
                  setTotalChecked(e.target.checked)
                  setSignUpChecked(e.target.checked)
                  setPersonalChecked(e.target.checked)
                }}
              />
              <SignUpPageTermsInfo>전체 선택</SignUpPageTermsInfo>
            </SignUpPageTermsCheckWrap>
            <SignUpPageTermsInfo2>회원가입약관</SignUpPageTermsInfo2>
            <SignUpPageTermsInnerWrap>해당 홈페이지에 맞는 회원가입약관을 입력합니다.</SignUpPageTermsInnerWrap>

            <SignUpPageTermsCheckWrap>
              <input
                type="checkbox"
                checked={signUpChecked}
                onChange={e => {
                  if (totalChecked) {
                    setTotalChecked(e.target.checked)
                  }

                  setSignUpChecked(e.target.checked)
                }}
              />
              <SignUpPageTermsInfo>회원가입약관의 내용에 동의합니다.</SignUpPageTermsInfo>
            </SignUpPageTermsCheckWrap>
            <SignUpPageTermsInfo2>개인정보처리방침안내</SignUpPageTermsInfo2>
            <CustomTable>
              <thead>
                <tr>
                  <th scope="col">목적</th>
                  <th scope="col">항목</th>
                  <th scope="col">보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>이용자 식별 및 본인여부 확인</td>
                  <td>아이디, 이름, 비밀번호</td>
                  <td>회원 탈퇴 시까지</td>
                </tr>
                <tr>
                  <td>
                    고객서비스 이용에 관한 통지, <br />
                    CS대응을 위한 이용자 식별
                  </td>
                  <td>연락처 (이메일, 휴대전화번호)</td>
                  <td>회원 탈퇴 시까지</td>
                </tr>
              </tbody>
            </CustomTable>
            <SignUpPageTermsCheckWrap>
              <input
                type="checkbox"
                checked={personalChecked}
                onChange={e => {
                  if (totalChecked) {
                    setTotalChecked(e.target.checked)
                  }
                  setPersonalChecked(e.target.checked)
                }}
              />
              <SignUpPageTermsInfo>개인정보처리방침안내의 내용에 동의합니다.</SignUpPageTermsInfo>
            </SignUpPageTermsCheckWrap>
            <SignUpPageFormButtonWrap>
              <SignUpPageFormButton
                variant="secondary"
                type="button"
                onClick={e => {
                  if (!signUpChecked) {
                    alert('회원가입약관의 내용에 동의하셔야 회원가입 하실 수 있습니다.')
                    return
                  }

                  if (!personalChecked) {
                    alert('개인정보처리방침안내의 내용에 동의하셔야 회원가입 하실 수 있습니다.')
                    return
                  }
                  setIsProcess(true)
                }}
              >
                회원가입
              </SignUpPageFormButton>
            </SignUpPageFormButtonWrap>
          </SignUpPageTermsWrap>
        ) : (
          <SignUpPageFormWrap>
            <CustomForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CustomFormLabel>
                <SignUpPageFormIcon />
                계좌 개설안내
              </CustomFormLabel>
              <CustomFormGroupInnerWrap>
                <CustomFormGroup>
                  <CustomFormCheck
                    inline
                    required
                    id="1"
                    name="account"
                    value="noreal"
                    type="radio"
                    label="모의계좌"
                    onChange={e => {
                      setMakeAccount(e.target.value)
                    }}
                  />
                  <CustomFormCheck
                    inline
                    required
                    id="2"
                    name="account"
                    value="real"
                    type="radio"
                    label="실 계좌"
                    onChange={e => {
                      setMakeAccount(e.target.value)
                    }}
                  />
                </CustomFormGroup>
              </CustomFormGroupInnerWrap>
              <CustomFormLabel>
                <SignUpPageFormIcon />
                사이트 이용정보 입력
              </CustomFormLabel>
              <CustomFormGroupInnerWrap>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberId"
                      type="text"
                      placeholder="아이디"
                      required
                      defaultValue={memberId}
                      onChange={e => setMemberId(e.target.value)}
                    />

                    <Form.Control.Feedback type="invalid">아이디를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                  <Form.Text id="passwordHelpBlock" muted>
                    영문자, 숫자, 최소 3자이상 입력하세요.
                  </Form.Text>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberPassword"
                      type="password"
                      placeholder="비밀번호"
                      required
                      defaultValue={memberPassword}
                      onChange={e => setMemberPassword(e.target.value)}
                    />

                    <Form.Control.Feedback type="invalid">비밀번호를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberPasswordConfirm"
                      type="password"
                      placeholder="비밀번호 확인"
                      required
                      defaultValue={memberPasswordConfirm}
                      onChange={e => setMemberPasswordConfirm(e.target.value)}
                    />

                    <Form.Control.Feedback type="invalid">비밀번호를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
              </CustomFormGroupInnerWrap>

              <CustomFormLabel>
                <SignUpPageFormIcon />
                개인정보 입력
              </CustomFormLabel>
              <CustomFormGroupInnerWrap>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberName"
                      type="text"
                      placeholder="이름"
                      required
                      defaultValue={memberName}
                      onChange={e => setMemberName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">이름을 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberNickName"
                      type="text"
                      placeholder="닉네임"
                      required
                      defaultValue={memberNickName}
                      onChange={e => setMemberNickName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">닉네임을 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                  <Form.Text id="passwordHelpBlock" muted>
                    공백없이 한글,영문,숫자만 입력 해주세요 (한글2자, 영문4자 이상) <br /> 닉네임을 바꾸시면 앞으로 60일
                    이내에는 변경 할 수 없습니다.
                  </Form.Text>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberEmail"
                      type="text"
                      placeholder="이메일"
                      required
                      defaultValue={memberEmail}
                      onChange={e => setMemberEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">이메일를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberPhoneNum"
                      type="text"
                      placeholder="휴대폰번호"
                      required
                      defaultValue={memberPhoneNum}
                      onChange={e => setMemberPhoneNum(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">휴대폰 번호를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberBankName"
                      type="text"
                      placeholder="은행명"
                      required
                      defaultValue={memberBankName}
                      onChange={e => setMemberBankName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">은행명을 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberAccountNum"
                      type="text"
                      placeholder="계좌번호"
                      required
                      defaultValue={memberAccountNum}
                      onChange={e => setMemberAccountNum(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">계좌번호를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
                <CustomFormGroup>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="memberAccountHolder"
                      type="text"
                      placeholder="예금주"
                      required
                      defaultValue={memberAccountHolder}
                      onChange={e => setMemberAccountHolder(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">예금주를 입력해주세요.</Form.Control.Feedback>
                  </InputGroup>
                </CustomFormGroup>
              </CustomFormGroupInnerWrap>

              <CustomFormLabel>
                <SignUpPageFormIcon />
                기타 개인설정
              </CustomFormLabel>
              <CustomFormGroupInnerWrap>
                <CustomFormGroup>
                  <CustomFormLabel>SMS 수신여부</CustomFormLabel>
                  <CustomFormCheck
                    type="checkbox"
                    label="휴대폰 문자메세지를 받겠습니다."
                    onChange={e => {
                      setSmsReceived(e.target.checked)
                    }}
                  />
                </CustomFormGroup>
                <CustomFormGroup>
                  <Form.Control
                    name="recommendedId"
                    type="text"
                    placeholder="추천인 아이디"
                    defaultValue={recommendedId}
                    onChange={e => setRecommendedId(e.target.value)}
                  />
                </CustomFormGroup>
              </CustomFormGroupInnerWrap>
              <SignUpPageFormButtonWrap>
                <SignUpPageFormButton variant="secondary" type="submit">
                  회원가입
                </SignUpPageFormButton>
              </SignUpPageFormButtonWrap>
            </CustomForm>
          </SignUpPageFormWrap>
        )}
      </SignUpPageInnerWrap>
    </SignUpPageWrap>
  )
}

export default SignUpPage

const SignUpPageWrap = styled.div`
  width: 100%;

  padding-bottom: 40px;
`
const SignUpPageInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 40px;
`

const SignUpPageHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const SignUpPageHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  color: #000;
`
const SignUpPageHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

// 약관
const SignUpPageTermsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: flex-start;

  gap: 15px;
`

const SignUpPageTermsIcon = styled(FaExclamationCircle)`
  width: 16px;
  height: 16px;
`

const SignUpPageTermsInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;

  color: #000;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 5px;
`

const SignUpPageTermsInfo2 = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;

  color: #000;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 10px;

  gap: 5px;
`

const SignUpPageTermsCheckWrap = styled.div`
  display: flex;
  gap: 5px;
`

const SignUpPageTermsInnerWrap = styled.div`
  width: 100%;
  height: 83px;
  background-color: #e9ecef;

  color: #495057;

  padding: 6px 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

const CustomTable = styled(Table)`
  table {
    color: #fff !important;
  }

  thead {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff !important;
    background-color: #212529 !important;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  tbody {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff !important;
    background-color: #212529 !important;
  }

  th {
    border: 1px solid #dee2e6;
  }

  td {
    border: 1px solid #dee2e6;
    text-align: center;
    vertical-align: middle;
  }
`

const SignUpPageFormButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SignUpPageFormButton = styled(Button)`
  width: 300px;

  background-color: #1d409f;
`

// 회원 가입 폼
const SignUpPageFormWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 15px;
`

const CustomForm = styled(Form)`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CustomFormGroup = styled(Form.Group)`
  margin-bottom: 10px;
`

const CustomFormGroupInnerWrap = styled.div`
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
`

const CustomFormLabel = styled(Form.Label)`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: left;

  display: flex;
  gap: 5px;
`

const CustomFormCheck = styled(Form.Check)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  gap: 5px;
`

const SignUpPageFormIcon = styled(FaRegEdit)`
  width: 18px;
  height: 16px;
`
