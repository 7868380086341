import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaLock } from 'react-icons/fa'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { findMemberInfo, siginIn, updateMemberPwd } from '../../api/auth/auth'
import { setUserId } from '../../utils/authority'

const MobileLoginFindPage = () => {
  const navigate = useNavigate

  // 비밀번호 재설정 여부
  const [pwCheck, setPwCheck] = useState(false)

  const [memberEmail, setMemberEmail] = useState('')

  const [memberId, setMemberId] = useState('dfd')
  const [memberPassword, setMemberPassword] = useState('')
  const [memberPasswordConfirm, setMemberPasswordConfirm] = useState('')

  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      if (!pwCheck) {
        // 이메일 확인
        const body = {
          memberEmail,
        }

        findMemberInfo(body)
          .then(res => {
            setMemberId(res.data.memberId)
            setPwCheck(true)
            alert('새로운 비밀번호로 변경해주세요.')
          })
          .catch(error => {
            console.log('error => ', error)
            alert('존재하지 않는 정보입니다. 다시 확인해주세요.')
          })
        return
      }

      if (memberPassword !== memberPasswordConfirm) {
        alert('비밀번호가 일치하지 않습니다.')
        return
      }

      // 비밀번호 변경
      const body = {
        memberEmail,
        memberId,
        memberPassword,
      }

      updateMemberPwd(body)
        .then(res => {
          alert('비밀번호 변경되었습니다.')
          window.location.href = '/login'
        })
        .catch(error => {
          console.log('error => ', error)
          alert('비밀번호 변경실패했습니다. . 다시 시도해주세요. ')
          window.location.reload()
        })
    }

    setValidated(true)
  }

  return (
    <LoginPageWrap>
      <LoginPageInnerWrap>
        <LoginPageHeader>
          <LoginPageHeaderInfo>회원정보 찾기</LoginPageHeaderInfo>
          <LoginPageHeaderLine />
        </LoginPageHeader>
        <LoginPageContentWrap>
          <LoginPageFormIconWrap>
            <LoginPageFormIcon color="gray" />
          </LoginPageFormIconWrap>
          <LoginPindInfoWrap>
            <LoginPindInfo>회원가입 시 등록하신 이메일 주소를 입력해 주세요.</LoginPindInfo>
            <LoginPindInfo>이메일 확인시 아이디 확인 및 비밀번호 재설정을 하실 수 있습니다.</LoginPindInfo>
          </LoginPindInfoWrap>

          <LoginPageFormWrap>
            <CustomForm noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3 mt-4">
                <Form.Label>이메일</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    name="memberEmail"
                    type="text"
                    placeholder="이메일"
                    required
                    disabled={pwCheck}
                    defaultValue={memberEmail}
                    onChange={e => setMemberEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">이메일를 입력해주세요.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {pwCheck ? (
                <>
                  <Form.Group className="mb-3 mt-1">
                    <Form.Label>아이디</Form.Label>
                    <Form.Control name="memberId" type="text" disabled={pwCheck} defaultValue={memberId} />
                  </Form.Group>
                  <Form.Group className="mb-3 mt-1">
                    <Form.Label>새로운 비밀번호</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name="memberPassword"
                        type="password"
                        placeholder="새로운 비밀번호"
                        required
                        defaultValue={memberPassword}
                        onChange={e => setMemberPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">비밀번호를 입력해주세요.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3 mt-1">
                    <Form.Label>새로운 비밀번호 확인</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name="memberPasswordConfirm"
                        type="password"
                        placeholder="새로운 비밀번호 확인"
                        required
                        defaultValue={memberPasswordConfirm}
                        onChange={e => setMemberPasswordConfirm(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">비밀번호를 입력해주세요.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}

              {pwCheck ? (
                <LoginPageFormButton variant="primary" type="submit">
                  변경
                </LoginPageFormButton>
              ) : (
                <LoginPageFormButton variant="primary" type="submit">
                  찾기
                </LoginPageFormButton>
              )}
            </CustomForm>
          </LoginPageFormWrap>
        </LoginPageContentWrap>
      </LoginPageInnerWrap>
    </LoginPageWrap>
  )
}

export default MobileLoginFindPage

const LoginPindInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 15px;

  gap: 10px;
`

const LoginPindInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  color: #000;
`

const LoginPageWrap = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 136px;
`

const LoginPageInnerWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 40px;
`

const LoginPageHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`

const LoginPageHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  color: #000;
`
const LoginPageHeaderLine = styled.div`
  width: 90px;
  border: 1.5px solid #d51820;
  display: block;
`

const LoginPageContentWrap = styled.div`
  width: 350px;

  margin: 0 auto;

  background: #f7f7f7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 40px 40px;

  border-radius: 2px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
`

const CustomForm = styled(Form)`
  width: 100%;
`

const LoginPageFormIconWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginPageFormIcon = styled(FaLock)`
  width: 70px;
  height: 80px;
`

const LoginPageFormWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginPageFormButton = styled(Button)`
  width: 100%;

  background-color: #1d409f;
`

const LoginPageInfoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;

  gap: 5px;
`
const LoginPageInfo = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000;

  &:hover {
    cursor: pointer;
  }
`
