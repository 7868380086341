import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Header = () => {
  const navigate = useNavigate()

  return (
    <HeaderWrap>
      <HeaderTopWrap>
        <HeaderTopInnerWrap>
          <HeaderTopLogoImgWrap>
            <HeaderTopLogoImg
              src={`${process.env.PUBLIC_URL}/img/main/logo.png`}
              alt=""
              onClick={e => {
                navigate('/')
              }}
            />
          </HeaderTopLogoImgWrap>
          <HeaderMidInnerWrap>
            <HeaderMidNavbar>
              <Navbar.Collapse>
                <Nav>
                  <HeaderMidNavDropdown title="이용안내">
                    <HeaderMidNavDropdown.Item href="/information/guide">이용가이드</HeaderMidNavDropdown.Item>
                    <HeaderMidNavDropdown.Item href="/information/money-guide">입출금가이드</HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                  <HeaderMidNavDropdown title="트레이딩">
                    <HeaderMidNavDropdown.Item href="/trading/foreigngift">해외선물</HeaderMidNavDropdown.Item>
                    <HeaderMidNavDropdown.Item href="/trading/economic">경제캘린더</HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                  <HeaderMidNavLink href="/trading/regulation">거래규정</HeaderMidNavLink>
                  <HeaderMidNavDropdown title="다운로드">
                    <HeaderMidNavDropdown.Item href="/download/pc">PC 버전</HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                  <HeaderMidNavDropdown title="고객센터">
                    <HeaderMidNavDropdown.Item href="/customcenter">24시간 상담센터</HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                </Nav>
              </Navbar.Collapse>
            </HeaderMidNavbar>
          </HeaderMidInnerWrap>
        </HeaderTopInnerWrap>
      </HeaderTopWrap>
    </HeaderWrap>
  )
}

export default Header

const HeaderWrap = styled.div`
  width: 100%;
  height: 74px;
  position: fixed;
  top: 0px;
  border-bottom: solid 1px #ccc;
  background: white;
  display: flex;
  align-items: center;
  z-index: 1000;
`
const HeaderTopWrap = styled.div`
  width: 100%;
  // height: 43px;
`

const HeaderTopInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  gap: 10px;
`

const HeaderMidInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
`

const HeaderMidNavbar = styled(Navbar)``

const HeaderMidNavDropdown = styled(NavDropdown)`
  // height: 20px;
  width: 150px;
  border-right: solid 1px #ccc;
  // border: solid 3px red;
  text-align: center;
  .nav-item {
    color: black !important;
    font-size: 17px;
  }

  .nav-link {
    color: black !important;
    font-size: 17px;
  }

  .nav-link:hover {
    color: red;
  }
`

const HeaderMidNavLink = styled(Nav.Link)`
  // height: 20px;
  color: black !important;
  font-size: 17px;
  text-align: center;
  width: 150px;
  border-right: solid 1px #ccc;
  &:hover,
  &:focus,
  &:active {
    color: red !important;
  }

  &.active,
  &.active:focus,
  &.active:hover {
    color: white !important;
  }
`

const HeaderTopLogoImgWrap = styled.div`
  // width: 240px;
  height: 100%;
`

const HeaderTopLogoImg = styled.img`
  width: 200px;
  &:hover {
    cursor: pointer;
  }

  z-index: 500;
`
