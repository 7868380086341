import React from 'react'
import styled from 'styled-components'

const MobileMainContent1 = () => {
  return (
    <MainContent1Wrap>
      <MainContent1InnerWrap>
        <MainContent1TitleWrap>
          <MainContent1Title>제공 선물상품</MainContent1Title>
        </MainContent1TitleWrap>
        <MainContent1ContentWrap>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main01.png`} />
            <MainContent1ContentTitle>금리선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              금융선물의 종류 중 하나입니다. 대표적인 상품으로 미국 재무성채권과 유로 달러 독일 금리선물 등이 있습니다.
            </MainContent1ContentContent>
          </MainContent1Content>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main02.png`} />
            <MainContent1ContentTitle>주가지수선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              금융선물의 종류 중 하나입니다. 미국 다우지수, S&P 500, 나스닥지수, 일본 니케이, 홍콩 항셍지수 등이
              있습니다.
            </MainContent1ContentContent>
          </MainContent1Content>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main03.png`} />
            <MainContent1ContentTitle>통화선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              금융선물의 종류 중 하나입니다. 호주달러화, 영국파운드화, 유로화, 캐나다달러화, 스위스프랑화, 일본엔화 등을
              중심으로 선물 거래가 이루어집니다.
            </MainContent1ContentContent>
          </MainContent1Content>
          <MainContent1Content>
            <MainContent1ContentImg src={`${process.env.PUBLIC_URL}/img/main/main04.png`} />
            <MainContent1ContentTitle>상품선물</MainContent1ContentTitle>
            <MainContent1ContentContent>
              원유, 난방유, 가솔린 등 에너지와 비철금속, 귀금속, 농산물, 커피, 코코아 등의 연성상품과 소, 돼지고기 등의
              축산물을 중심의 상품선물이 있습니다.
            </MainContent1ContentContent>
          </MainContent1Content>
        </MainContent1ContentWrap>
      </MainContent1InnerWrap>
    </MainContent1Wrap>
  )
}

export default MobileMainContent1

const MainContent1Wrap = styled.div`
  width: 100%;
  min-height: 630px;
  position: relative;

  background: #f8f8f6;

  padding: 12px;
`

const MainContent1InnerWrap = styled.div`
  width: 100%;
  height: 610px;
  margin: 0 auto;
  border-radius: 16px;
  background: white;

  display: flex;
  flex-direction: column;

  padding: 20px 12px 0;
`

const MainContent1TitleWrap = styled.div`
  display: block;
  color: #000;
  text-align: center;
`

const MainContent1Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
  color: #252831;
`
const MainContent1SubTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;
  color: #252831;
`

const MainContent1ContentWrap = styled.div`
  width: 100%;

  // display: flex;
  // justify-content: space-between;
  margin-top: 20px;
`

const MainContent1Content = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 6px);

  &:nth-child(2n-1) {
    margin-right: 12px;
  }

  img {
    width: 100%;
    margin-bottom: 8px;
  }
`
const MainContent1ContentImg = styled.img`
  vertical-align: top;
  // height: 180px;
`

const MainContent1ContentTitle = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #252831;
`

const MainContent1ContentContent = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 14px;
  text-align: center;
  color: #252831;
`
