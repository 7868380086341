import React, { useState } from 'react'
import { Button, CloseButton, Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { clearLocalStorage, getUserId, isLogin } from '../../utils/authority'

const MobileHeader = () => {
  const navigate = useNavigate()

  const [mobileMenuShow, setMobileMenuShow] = useState(false)

  const [closeCheck, setCloseCheck] = useState(false)

  return (
    <HeaderWrap>
      <HeaderTopWrap>
        <HeaderTopInnerWrap>
          <HeaderTopLogoImgWrap>
            <HeaderTopLogoImg
              src={`${process.env.PUBLIC_URL}/img/main/logo.png`}
              alt=""
              onClick={e => {
                navigate('/')
              }}
            />
          </HeaderTopLogoImgWrap>

          <CustomNavbar expand={false}>
            <Navbar.Toggle />
            <CustomNavbarOffcanvas placement="end" responsive="sm">
              <Offcanvas.Header closeButton closeVariant="white">
                <Offcanvas.Title />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavDropdown title="이용안내">
                    <NavDropdown.Item href="/mobile/information/guide">이용가이드</NavDropdown.Item>
                    <NavDropdown.Item href="/mobile/information/money-guide">입출금가이드</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="트레이딩">
                    {/* <NavDropdown.Item href="/mobile/trading/domesticgift">국내선물</NavDropdown.Item> */}
                    <NavDropdown.Item href="/mobile/trading/foreigngift">해외선물</NavDropdown.Item>
                    {/* <NavDropdown.Item href="/mobile/trading/stockproduct">주식상품</NavDropdown.Item>
                    <NavDropdown.Item href="/mobile/trading/moneyproduct">화폐상품</NavDropdown.Item> */}
                    <NavDropdown.Item href="/mobile/trading/economic">경제캘린더</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/mobile/trading/regulation">거래규정</Nav.Link>
                  <NavDropdown title="다운로드">
                    <NavDropdown.Item href="https://www.daeyoungasset.com">모바일 버전</NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link href="/mobile/partnership">파트너쉽</Nav.Link> */}
                  {/* <Nav.Link href="/mobile/promotion">프로모션</Nav.Link> */}
                  <NavDropdown title="고객센터">
                    {/* <NavDropdown.Item href="/mobile/monitoring">원격지원</NavDropdown.Item> */}
                    <NavDropdown.Item href="/mobile/customcenter">24시간 상담센터</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </CustomNavbarOffcanvas>
          </CustomNavbar>
        </HeaderTopInnerWrap>
      </HeaderTopWrap>
    </HeaderWrap>
  )
}

export default MobileHeader

const CustomNavbar = styled(Navbar)`
  width: 100%;
  button {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
    margin-left: auto;
  }
`

const CustomNavbarOffcanvas = styled(Navbar.Offcanvas)`
  background: black;
  color: white;
`

const HeaderWrap = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #ccc;
  background: white;
  z-index: 1000;
`
const HeaderTopWrap = styled.div`
  width: 100%;
`

const HeaderTopInnerWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
`

const HeaderTopLogoImgWrap = styled.div`
  padding-left: 10px;
`

const HeaderTopLogoImg = styled.img`
  width: 200px;
  &:hover {
    cursor: pointer;
  }

  z-index: 200;
`
