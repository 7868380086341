import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const MobileDownload = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <br />
          모바일 버전
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          DAOL의 모바일 프로그램은 언제 어디서나 쉽고 빠르게 트레이딩이 가능한 시스템 입니다.
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          (IOS 운영체제는 자사 규정으로 인해 모바일 서비스가 지원되지 않으니, 참고바랍니다)
        </CompanyIntroductionP>

        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <span>
            <img src="https://daol1234.kr/theme/daol/asset/images/main/mts.png" width={200} height={200} alt="" />
          </span>
        </CompanyIntroductionP>
        <CompanyIntroductionP style={{ marginTop: '50px' }}>
          <a
            href={`${process.env.PUBLIC_URL}/file/easystock-setup-1.0.3.exe`}
            download
            style={{ background: 'red', color: 'white' }}
          >
            &nbsp;&nbsp; MTS 다운로드 &nbsp;&nbsp;
          </a>
        </CompanyIntroductionP>
      </CompanyIntroductionContent>
    </CompanyIntroductionWrap>
  )
}
export default MobileDownload

export const CompanyIntroductionWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
