import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'
import { FaDownload } from 'react-icons/fa'
import { Button } from 'react-bootstrap'
import SilverButtonH40 from '../../shared/components/SilverButton'

const PCDownload = () => {
  const onClickDownloadHandler = () => {
    const link = document.createElement('a')
    link.href = `https://drive.google.com/file/d/1Q3tvC8npjtWxQ5MTTsU8UanC8glUxrLr`
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.setAttribute('role', 'button')

    document.body.appendChild(link)
    link.click()
  }

  return (
    <div
      style={{
        width: '100%',
        background: '#f8f8f6',
        padding: '80px 0',
      }}
    >
      <CompanyIntroductionWrap>
        <CompanyIntroductionHeader>
          <CompanyIntroductionH3>PC 버전</CompanyIntroductionH3>
        </CompanyIntroductionHeader>
        <CompanyIntroductionContent>
          <CompanyIntroductionP style={{ marginTop: '30px' }}>
            <span>
              <img src={`${process.env.PUBLIC_URL}/img/main/down1.jpg`} alt="" />
            </span>
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ margin: '30px 0' }}>
            <DownloadButton variant="outline-secondary" onClick={onClickDownloadHandler}>
              다운로드
            </DownloadButton>
            {/* <DownloadButton onClick={onClickDownloadHandler}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DownloadButtonInfo>EHTS 다운로드</DownloadButtonInfo>
                <DownloadButtonIcon />
              </div>
            </DownloadButton> */}
          </CompanyIntroductionP>
          <div
            style={{
              height: '100%',
              background: '#f3f3f3',
              textAlign: 'center',
              verticalAlign: 'left',
              fontSize: '20px',
              fontWeight: '900',
              padding: '40px 40px',
              marginTop: '20px',
              display: 'flex',
              border: '1px solid #cfcfcf',
            }}
          >
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                verticalAlign: 'left',
                fontSize: '20px',
                fontWeight: '900',
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
                paddingLeft: '20px',
                // marginTop: '20px',
              }}
            >
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;주문, 포지션, 익스포져의 온라인 관리
              </CompanyIntroductionP>
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;다중계정관리
              </CompanyIntroductionP>
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;실시간 종목 모니터링
              </CompanyIntroductionP>
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;테크니컬 분석
              </CompanyIntroductionP>
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                verticalAlign: 'left',
                fontSize: '20px',
                fontWeight: '900',
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
                paddingLeft: '20px',
                // marginTop: '20px',
              }}
            >
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;손쉬운 조작성
              </CompanyIntroductionP>
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;모든매매기록보존
              </CompanyIntroductionP>
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;모든 체결 모드 지원
              </CompanyIntroductionP>
              <CompanyIntroductionP style={{ textAlign: 'left' }}>
                <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
                &nbsp;&nbsp;한가지의 플랫폼에서 모든 상품 거래가능
              </CompanyIntroductionP>
            </div>
          </div>
        </CompanyIntroductionContent>
      </CompanyIntroductionWrap>
    </div>
  )
}
export default PCDownload

const DownloadButton = styled(Button)`
  width: 300px;
  height: 50px;

  font-size: 25px;
`

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  min-height: 1050px;
  border-radius: 16px;
  background: white;
  padding: 0 40px;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  // margin-top: 0;
  text-align: center;
  // margin-bottom: 1rem;
  // display: block;
  // margin-block-start: 1em;
  // margin-block-end: 1em;
  // margin-inline-start: 0px;
  // margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`

// const DownloadButtonIcon = styled(FaDownload)`
//   color: white;

//   margin-left: 20px;

//   width: 40px;
//   height: 40px;

//   // border: 1px solid red;
// `

// const DownloadButtonInfo = styled.span`
//   // width: 100%;

//   font-style: italic;
//   font-weight: 800;
//   font-size: 40px;
//   line-height: 40px;

//   color: white;
// `

// const DownloadButton = styled(SilverButtonH40)`
//   height: 75px;
//   width: 370px;

//   border-radius: 0px;

//   border: 5px solid transparent;

//   // 마우스 호버
//   &:hover {
//     ${DownloadButtonInfo} {
//       color: #ffc01a;
//     }

//     ${DownloadButtonIcon} {
//       color: #ffc01a;
//     }
//   }

//   // 클릭
//   &:focus,
//   &:active,
//   &active:focus {
//     ${DownloadButtonInfo} {
//       color: #ffc01a;
//     }
//     ${DownloadButtonIcon} {
//       color: #ffc01a;
//     }
//   }

//   // 비활성화
//   &:disabled {
//     ${DownloadButtonInfo} {
//       color: #ffffff4d;
//     }
//   }
// `
