import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <FooterWrap>
      <FooterInnerWrap>
        {/* <FooterMenu>
          <FooterMenuTitle>이용안내</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/information/guide'
              }}
            >
              이용 가이드
            </FooterMenuContent>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/information/money-guide'
              }}
            >
              입출금 가이드
            </FooterMenuContent>
          </div>
        </FooterMenu> */}
        <FooterMenu>
          <FooterMenuTitle>트레이딩</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/trading/foreigngift'
              }}
            >
              해외선물
            </FooterMenuContent>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/trading/economic'
              }}
            >
              경제캘린더
            </FooterMenuContent>
          </div>
        </FooterMenu>
        <FooterMenu>
          <FooterMenuTitle>거래규정</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/trading/regulation'
              }}
            >
              거래규정
            </FooterMenuContent>
          </div>
        </FooterMenu>
        <FooterMenu>
          <FooterMenuTitle>다운로드</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/download/pc'
              }}
            >
              다운로드
            </FooterMenuContent>
          </div>
        </FooterMenu>
        <FooterMenu>
          <FooterMenuTitle>문의</FooterMenuTitle>
          <div>
            {/* <FooterMenuContent
              onClick={e => {
                window.location.href = '/monitoring'
              }}
            >
              이용자 가이드
            </FooterMenuContent> */}
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/customcenter'
              }}
            >
              24시간 상담센터
            </FooterMenuContent>
          </div>
          <FootterIconBox>
            <div>
              <img alt="" src={`${process.env.PUBLIC_URL}/img/main/footer-icon01.png`} height="20px" />
            </div>
            <div>
              <img alt="" src={`${process.env.PUBLIC_URL}/img/main/footer-icon02.png`} height="20px" />
            </div>
            <div>
              <img alt="" src={`${process.env.PUBLIC_URL}/img/main/footer-icon03.png`} height="20px" />
            </div>
          </FootterIconBox>
        </FooterMenu>
      </FooterInnerWrap>
      <FooterLogoBox>
        <div>
          <img alt="" src={`${process.env.PUBLIC_URL}/img/main/logo.png`} width="210px" height="auto" />
        </div>
        <p style={{ color: 'black' }}>
          <img src={`${process.env.PUBLIC_URL}/img/main/danger.png`} alt="" height="12px" />
          <span style={{ color: '#ff1100' }}>위험 고지</span> 대영은 최상의 서비스와 최고의 기술지원을 위해 투자합니다.
          따라서 큰 주가 변동에서도 안정된 체결과 주문 에러가 일어나지 않습니다. <br />
          다만 큰 유동성의 상품은 단점으로 작용될 수 있습니다. 거래를 결정하기 전, 본인의 투자 목표와 경험, 수반되는
          위험 수준을 충분히 고려하여 접근하셔야됩니다. <br /> 대영은 본인이 필요한 경우 독립적인 투자 상담 및 전문적인
          트레이딩을 무한 지원해드립니다.
          <br />
          Copyright © DAEYOUNG 2018. All rights reserved.
        </p>
      </FooterLogoBox>
    </FooterWrap>
  )
}

export default Footer

const FooterWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: block;
  position: relative;
  // top: 260px;

  background: #fff;
  padding: 60px 0;
`

const FooterInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  padding: 0 150px;
`

const FooterMenuTitle = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: #fdaa12;
  margin-bottom: 8px;
`

const FooterMenuContent = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: #333;
  margin-bottom: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 20px;
  }

  &:hover {
    color: #fdaa12;
  }
`

const FooterMenu = styled.div`
  width: 160px;
`

const FootterIconBox = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  div {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
`

const FooterLogoBox = styled.div`
  width: 100%;
  margin-top: 28px;
  text-align: center;

  p {
    margin-top: 28px;
    word-break: break-all;
    font-size: 12px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.38);
    font-weight: 400;
  }
`

const FooterFirstWrap = styled.div`
  width: 100%;
  min-height: 243px;

  // background-image: url(${process.env.PUBLIC_URL}/img/main/cop.jpg);
  background: url(${process.env.PUBLIC_URL}/img/main/cop1.png) no-repeat center;
  // background-position: center;

  display: flex;
  justify-content: end;
  align-items: center;

  padding-left: 50px;
`

const FooterFirstInfoWrap = styled.div`
  width: calc(100% / 2);

  display: flex;
  flex-direction: column;

  gap: 15px;

  float: right;
`

const FooterFirstInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;

  color: #000;
`

const FooterSecondWrap = styled.div`
  background: #262626;
  min-height: 50px;
`

const FooterSecondInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;

  padding: 20px 0;
`

const FooterSecondInnerIcon = styled.img`
  width: 24px;
  height: 20px;
`

const FooterSecondInnerInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;

  color: rgb(119, 119, 119);
`

const FooterThirdWrap = styled.div`
  background: #000;
  padding: 24px 0;
`

const FooterThirdInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FooterThirdInnerInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
`
