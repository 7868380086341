import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const MoneyProduct = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <img src="https://daol1234.kr/img/p_04.jpg" width={199} height={199} alt="" />
          <br />
          화폐상품
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
          <thead style={{ borderTop: 'solid 2px #dee2e6' }}>
            <tr style={{ borderTop: 'solid 2px #dee2e6' }}>
              <GiftTh>대상품목</GiftTh>
              <GiftTh>최저 스프레드</GiftTh>
              <GiftTh>평균 스프레드</GiftTh>
              <GiftTh>PIP가치</GiftTh>
              <GiftTh>레버리지</GiftTh>
              <GiftTh>거래시간( GMT+0 ) </GiftTh>
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>BTCUSD</GiftTd>
              <GiftTd>50.2</GiftTd>
              <GiftTd>60.5</GiftTd>
              <GiftTd>0.1</GiftTd>
              <GiftTd>5</GiftTd>
              <GiftTd>
                Saturday 08 : 00 ~ Sunday24 : 00
                <br />
                <br />
                Monday 00 : 00 ~ Saturday 02 : 00
              </GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>BCHUSD</GiftTd>
              <GiftTd>28.7</GiftTd>
              <GiftTd>35.6</GiftTd>
              <GiftTd>0.1</GiftTd>
              <GiftTd>5</GiftTd>
              <GiftTd>
                Saturday 08 : 00 ~ Sunday24 : 00
                <br />
                <br />
                Monday 00 : 00 ~ Saturday 02 : 00
              </GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>ETHUSD</GiftTd>
              <GiftTd>15.2</GiftTd>
              <GiftTd>18.3</GiftTd>
              <GiftTd>0.1</GiftTd>
              <GiftTd>5</GiftTd>
              <GiftTd>
                Saturday 08 : 00 ~ Sunday24 : 00
                <br />
                <br />
                Monday 00 : 00 ~ Saturday 02 : 00
              </GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6' }}>
              <GiftTd>LTCUSD</GiftTd>
              <GiftTd>2.1</GiftTd>
              <GiftTd>3.1</GiftTd>
              <GiftTd>0.1</GiftTd>
              <GiftTd>5</GiftTd>
              <GiftTd>
                Saturday 08 : 00 ~ Sunday24 : 00
                <br />
                <br />
                Monday 00 : 00 ~ Saturday 02 : 00
              </GiftTd>
            </tr>
            <tr style={{ height: '80px', borderTop: 'solid 2px #dee2e6', borderBottom: 'solid 2px #dee2e6' }}>
              <GiftTd>BTCUSD</GiftTd>
              <GiftTd>1.6</GiftTd>
              <GiftTd>2.2</GiftTd>
              <GiftTd>1</GiftTd>
              <GiftTd>5</GiftTd>
              <GiftTd>
                Saturday 08 : 00 ~ Sunday24 : 00
                <br />
                <br />
                Monday 00 : 00 ~ Saturday 02 : 00
              </GiftTd>
            </tr>
          </tbody>
        </Table>
        {/* <CompanyIntroductionP>
          <CompanyIntroductionImg
            src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300019_1145.jpg"
            alt=""
          />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP> */}
      </CompanyIntroductionContent>
      {/* <CustomerCenterHeaderWrap>
        <CustomerCenterHeaderInfo>공지사항</CustomerCenterHeaderInfo>
      </CustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <CustomerCenterContentWrap>
          {isShowBoardContent ? (
            <NoticeContent boardContent={boardContent} setIsShowBoardContent={setIsShowBoardContent} />
          ) : (
            <ReactTableBase columns={boardListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          )}
        </CustomerCenterContentWrap>
      )} */}
    </CompanyIntroductionWrap>
  )
}
export default MoneyProduct

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  // padding: 0 0 0 20px;

  // display: flex;
  // align-items: center;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`
export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  vertical-align: middle;
  font-weight: bolder;
`
