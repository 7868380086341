import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import MainCarousel from './MainCarousel'
import MainContent from './MainContent'
import MainContentTwo from './MainContentTwo'
import MainDownloadContent from './MainDownloadContent'
import MainTraderPosition from './MainTraderPosition'
import MainTradingView from './MainTradingView'
import MeritContent from './MeritContent'
import MobileMainCarousel from './MobileMainCarousel'
import MobileMeritContent from './MobileMeritContent'
import MobileMainDownloadContent from './MobileMainDownloadContent'
import MobileMainTraderPosition from './MobileMainTraderPosition'
import MobileMainContent from './MobileMainContent'
import MobileMainContentTwo from './MobileMainContentTwo'
import MainContent1 from './MainContent1'
import MainContent2 from './MainContent2'
import MobileMainContent2 from './MobileMainContent2'
import MobileMainContent1 from './MobileMainContent1'

/**
 * @see : https://tradingview-widgets.jorrinkievit.xyz/docs/components/TickerTape
 */

const MainPage = () => {
  return (
    <>
      {isMobile ? (
        <>
          <MobileMainCarousel />
          <MainTradingView />
          {/* <MobileMeritContent />
          <MobileMainDownloadContent /> */}
          <MobileMainTraderPosition />
          <MobileMainContent1 />
          <MobileMainContent2 />
          {/* <MobileMainContent />
          <MobileMainContentTwo /> */}
        </>
      ) : (
        <>
          <MainCarousel />
          <MainTradingView />
          {/* <MeritContent />
          <MainDownloadContent /> */}
          <MainTraderPosition />
          <MainContent1 />
          <MainContent2 />
          {/* <MainContent />
          <MainContentTwo /> */}
        </>
      )}
    </>
  )
}

export default MainPage

const MainPageWrap = styled.div`
  width: 100%;
  position: relative;

  // top: 20px;
`
