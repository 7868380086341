import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const StockProduct = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <img src="https://daol1234.kr/img/p_03.jpg" width={199} height={199} alt="" />
          <br />
          주식상품
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>매매시간</CompanyIntroductionH4>
        <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '40px' }}>
          <GiftThead>
            <GiftTr>
              <GiftTh>대상품목</GiftTh>
              <GiftTh>장 개시전 매매</GiftTh>
              <GiftTh>정규 매매 시장</GiftTh>
              <GiftTh>시간 외 단일가 매매</GiftTh>
              <GiftTh>상장 종목 만기</GiftTh>
            </GiftTr>
          </GiftThead>
          <GiftTbody>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>유가 증권시장</GiftTd>
              <GiftTd>08:00 ~ 08:59</GiftTd>
              <GiftTd>09:00 ~ 15:25</GiftTd>
              <GiftTd>주문시간 조정</GiftTd>
              <GiftTd>매월 30일</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>코스피 시장</GiftTd>
              <GiftTd>08:00 ~ 08:59</GiftTd>
              <GiftTd>09:00 ~ 15:25</GiftTd>
              <GiftTd>주문시간 조정</GiftTd>
              <GiftTd>매월 30일</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>코스닥 시장</GiftTd>
              <GiftTd>08:00 ~ 08:59</GiftTd>
              <GiftTd>09:00 ~ 15:25</GiftTd>
              <GiftTd>주문시간 조정</GiftTd>
              <GiftTd>매월 30일</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>K-OTC 시장</GiftTd>
              <GiftTd>주문불가</GiftTd>
              <GiftTd>주문불가</GiftTd>
              <GiftTd>주문불가</GiftTd>
              <GiftTd>매월 30일</GiftTd>
            </GiftTr>
          </GiftTbody>
        </Table>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>매매체결</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ marginBottom: '-50px' }}>
          매매체결은 단일가격에 의한 개별경쟁매매와 복수가격에 의한 개별경쟁매매가 있습니다.
        </CompanyIntroductionP>
        <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
          <GiftThead>
            <GiftTr>
              <GiftTh>구분</GiftTh>
              <GiftTh>내용</GiftTh>
            </GiftTr>
          </GiftThead>
          <GiftTbody>
            <GiftTr style={{ height: '100px' }}>
              <GiftTd style={{ width: '180px' }}>가격 우선의 원칙</GiftTd>
              <GiftTd>
                <p style={{ marginBottom: '5px' }}>
                  - 주권, 외국주식예탁증서, 수익증권 및 주식관련사채권의 경우 저가의 매도호가는 고가의 매도호가에
                  우선하고 고가의 매수호가는 저가의 매수호가에 우선
                </p>
                <p style={{ marginBottom: '5px' }}>
                  - 채권의 경우 고수익률의 매도호가는 저수익률의 매도호가에 우선하고 저수익률의 매수호가는 고수익률의
                  매수호가에 우선
                </p>
                <p>
                  - 다만 시장가호가는 지정가호가에 가격적으로 우선하되, 매도시장가호가와 하한가의 매도지정가호가,
                  매수시장가호가와 상한가의 매수지정가호가는 각각 동일한 가격의 호가
                </p>
              </GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>시간 우선의 원칙</GiftTd>
              <GiftTd>
                <p>- 동일한 가격호가간 우선순위는 먼저 접수된 호가가 뒤에 접수된 호가에 우선</p>
              </GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>수량 우선의 원칙</GiftTd>
              <GiftTd>
                <p>- 동시호가의 수량이 많은 호가는 수량이 적은 호가에 우선</p>
              </GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>위탁매매 우선의 원칙</GiftTd>
              <GiftTd>
                <p style={{ marginBottom: '5px' }}>- 가격과 시간이 동일한 호가의 경우 위탁매매가 자기매매에 우선</p>
                <p>- 단,국채 딜러간 매매의 경우 예외</p>
              </GiftTd>
            </GiftTr>
          </GiftTbody>
        </Table>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>호가단위</CompanyIntroductionH4>
        <CompanyIntroductionP style={{ marginBottom: '-50px' }}>
          호가단위란 가격대별로 호가를 할 수 있는 최소단위의 가격을 의미합니다.
        </CompanyIntroductionP>
        <Table striped bordered hover style={{ borderCollapse: 'collapse', marginTop: '80px' }}>
          <GiftThead>
            <GiftTr>
              <GiftTh rowSpan={2}>구분</GiftTh>
              <GiftTh colSpan={4}>내용</GiftTh>
            </GiftTr>
            <GiftTr>
              <GiftTh>거래소</GiftTh>
              <GiftTh>코스닥</GiftTh>
              <GiftTh>코스피</GiftTh>
              <GiftTh>K-OTC</GiftTh>
            </GiftTr>
          </GiftThead>
          <GiftTbody>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>5,000원 미만</GiftTd>
              <GiftTd>5원</GiftTd>
              <GiftTd>5원</GiftTd>
              <GiftTd>5원</GiftTd>
              <GiftTd>5원</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>5,000원 이상 ~ 10,000원 미만</GiftTd>
              <GiftTd>10원</GiftTd>
              <GiftTd>10원</GiftTd>
              <GiftTd>10원</GiftTd>
              <GiftTd>10원</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>10,000원 이상 ~ 50,000원 미만</GiftTd>
              <GiftTd>50원</GiftTd>
              <GiftTd>50원</GiftTd>
              <GiftTd>50원</GiftTd>
              <GiftTd>50원</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>50,000원 이상 ~ 100,000원 미만</GiftTd>
              <GiftTd>100원</GiftTd>
              <GiftTd>100원</GiftTd>
              <GiftTd>100원</GiftTd>
              <GiftTd>100원</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>100,000원 이상 ~ 500,000원 미만</GiftTd>
              <GiftTd>500원</GiftTd>
              <GiftTd>100원</GiftTd>
              <GiftTd>500원</GiftTd>
              <GiftTd>500원</GiftTd>
            </GiftTr>
            <GiftTr style={{ height: '50px' }}>
              <GiftTd>500,000원 이상</GiftTd>
              <GiftTd>1,000원</GiftTd>
              <GiftTd>100원</GiftTd>
              <GiftTd>1,000원</GiftTd>
              <GiftTd>1,000원</GiftTd>
            </GiftTr>
          </GiftTbody>
        </Table>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>매매 수량단위</CompanyIntroductionH4>
        <CompanyIntroductionP>
          거래소시장에서 호가를 할 수 있는 최소단위 수량으로 유가증권시장 1주/ 코스닥시장 1주 / 코스피시장 1주 /
          K-OTC시장 1주를 규정 하고 있습니다.
        </CompanyIntroductionP>
        <CompanyIntroductionH4 style={{ marginTop: '80px' }}>공매도 주문허용</CompanyIntroductionH4>
        <div
          style={{
            marginTop: '30px',
            height: '100px',
            background: '#f3f3f3',
            textAlign: 'center',
            verticalAlign: 'center',
            padding: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ lineHeight: '20px' }}>
            공매도는 소유하지 않은 증권을 매도하는 것으로서, 무차입공매도가 금지된 우리 증시에서는 일반적으로 차입한
            증권을 매도(차입공매도)하는 것을 의미합니다. 투자자는 자신이 보유한 증권의 가격하락에 따른 손실을
            회피(헤지)하거나, 고평가된 증권의 매도를 통한 차익을 얻기 위해 주로 공매도를 활용하고 있습니다.
          </CompanyIntroductionP>
        </div>
        {/* <CompanyIntroductionP>
          <CompanyIntroductionImg
            src="https://www.daol1234.kr/data/editor/2110/f4d38b0254b1c83a6f28e454c8affdcf_1635300019_1145.jpg"
            alt=""
          />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP> */}
      </CompanyIntroductionContent>
      {/* <CustomerCenterHeaderWrap>
        <CustomerCenterHeaderInfo>공지사항</CustomerCenterHeaderInfo>
      </CustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <CustomerCenterContentWrap>
          {isShowBoardContent ? (
            <NoticeContent boardContent={boardContent} setIsShowBoardContent={setIsShowBoardContent} />
          ) : (
            <ReactTableBase columns={boardListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          )}
        </CustomerCenterContentWrap>
      )} */}
    </CompanyIntroductionWrap>
  )
}
export default StockProduct

export const CompanyIntroductionWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  // padding: 0 0 0 20px;

  // display: flex;
  // align-items: center;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
