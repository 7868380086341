import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'

const MobileMonitoring = () => {
  return (
    <CompanyIntroductionWrap>
      <CompanyIntroductionHeader>
        <CompanyIntroductionH3>
          <br />
          원격지원
        </CompanyIntroductionH3>
      </CompanyIntroductionHeader>
      <CompanyIntroductionContent>
        <CompanyIntroductionP>
          <CompanyIntroductionImg src={`${process.env.PUBLIC_URL}/img/main/cus4.jpg`} alt="" />
          <br style={{ clear: 'both' }} />
        </CompanyIntroductionP>

        <CompanyIntroductionH4 style={{ marginTop: '30px' }}>서비스 이용방법</CompanyIntroductionH4>

        <div
          style={{
            height: '250px',
            background: '#f3f3f3',
            verticalAlign: 'left',
            fontSize: '17px',
            fontWeight: '900',
            marginTop: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            &nbsp;&nbsp;원격지원 페이지 하단, "원격지원 연결" 클릭
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            &nbsp;&nbsp;EZHE 페이지 하단 고객접속프로그램 클릭 후 다운로드 진행
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            &nbsp;&nbsp;EZHELP 상담원 연결 화면에서 상담원 번호 "1번" 클릭
          </CompanyIntroductionP>
          <CompanyIntroductionP style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/img/main/cus0.png`} alt="" />
            &nbsp;&nbsp;상담원과 원격 연결 후 문제 해결
          </CompanyIntroductionP>
        </div>

        <div
          style={{
            height: '150px',
            background: '#f3f3f3',
            verticalAlign: 'center',
            marginTop: '20px',
            border: '1px solid #cfcfcf',
          }}
        >
          <CompanyIntroductionP>
            &nbsp;원격지원 서비스는 고객님의 지원 요청에 따라 상담원이 직접 고객님의 PC에 접속하여 원격으로 문제를
            해결해드리는 서비스 입니다.
          </CompanyIntroductionP>
          <CompanyIntroductionP>
            &nbsp;서비스 이용에 어려움이 있으신 분은 고객센터 <br />
            010-2397-1587 으로 연락 주시기 바랍니다.
          </CompanyIntroductionP>
        </div>
        <p style={{ textAlign: 'center', marginTop: '10px' }}>
          <a href="https://939.co.kr" target="" style={{ background: 'red', height: '30px', color: 'white' }}>
            &nbsp;&nbsp;원격지원 연결&nbsp;&nbsp;
          </a>
        </p>
      </CompanyIntroductionContent>
    </CompanyIntroductionWrap>
  )
}
export default MobileMonitoring

export const CompanyIntroductionWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  // overflow: auto;
  border: 1px solid transparent;
`

export const CompanyIntroductionImg = styled.img`
  width: 100%;
  height: 200px;
`

export const CompanyIntroductionHeader = styled.div`
  position: relative;
  top: 15px;
`

export const CompanyIntroductionH4 = styled.h4`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`

export const CompanyIntroductionH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

export const CompanyIntroductionContent = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
`

export const CompanyIntroductionP = styled.p`
  margin-top: 0;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const GiftTd = styled.td`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
`

export const GiftTh = styled.th`
  text-align: center;
  border: 2px solid #dee2e6;
  vertical-align: middle;
  font-weight: bolder;
`

export const GiftTbody = styled.tbody`
  border: 2px solid #dee2e6;
`

export const GiftThead = styled.thead`
  border: 2px solid #dee2e6;
`

export const GiftTr = styled.tr`
  border: 2px solid #dee2e6;
`
