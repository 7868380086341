import React from 'react'
import styled from 'styled-components'

const MobileMainCarousel = () => {
  return (
    <MainCarouselWrap>
      <MainInnerWrap>
        <MainContentWrap>
          <MainContentTitle>
            해외선물, 암호화폐
            <br /> 신뢰성 있는 <br /> 안전 거래시스템
          </MainContentTitle>
          <MainContentSubTitle>대영과 함께!</MainContentSubTitle>
          <MainCarouselButtonWrap>
            <MainCarouselButton href="https://www.daeyoungasset.com" role="button">
              <MainCarouselButtonImg src={`${process.env.PUBLIC_URL}/img/main/btn_03.png`} />
            </MainCarouselButton>
          </MainCarouselButtonWrap>
        </MainContentWrap>
        <MainVideoWrap>
          <MainVideo src={`${process.env.PUBLIC_URL}/img/main/main-video3.mov`} autoPlay loop muted />
        </MainVideoWrap>
      </MainInnerWrap>
    </MainCarouselWrap>
  )
}

export default MobileMainCarousel

const MainCarouselWrap = styled.div`
  width: 100%;
  height: 210px;
  position: relative;

  display: flex;
  justify-content: center;
  background: #ecfeff;
`

const MainInnerWrap = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  position: relative;
`

const MainContentWrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 101;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 10px;
  padding-left: 10px;
  // padding-bottom: 70px;

  gap: 15px;
`

const MainContentTitle = styled.p`
  width: 100%;
  // height: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252831;
  // text-align: center;
`
const MainContentSubTitle = styled.p`
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #252831;
`
const MainVideoWrap = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: end;

  position: relative;
`

const MainVideo = styled.video`
  height: 100%;
`

const MainCarouselButtonWrap = styled.div`
  display: flex;
`

const MainCarouselButton = styled.a`
  cursor: pointer;
  // padding: 0.25rem 0.5rem;
  // font-size: 0.875rem;
  // line-height: 1.5;
  border-radius: 0rem;
  width: 180px;
  font-weight: 400;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`

const MainCarouselButtonImg = styled.img`
  width: 100%;
`
