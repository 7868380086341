import React from 'react'
import styled from 'styled-components'

const MobileMoneyGuidePage = () => {
  return (
    <MobileMoneyGuidePageWrap>
      <MobileMoneyGuidePageInnerWrap>
        <MobileMoneyGuidePageHeader>
          <MobileMoneyGuidePageH3>입출금 가이드</MobileMoneyGuidePageH3>
        </MobileMoneyGuidePageHeader>
        {/* <MoneyGuidePageInnerTitle>입출금 안내</MoneyGuidePageInnerTitle> */}
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-1.png`} alt="" />
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-2.png`} alt="" />
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-3.png`} alt="" />
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-4.png`} alt="" />
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-5.png`} alt="" />
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-6.png`} alt="" />
        <MobileMoneyGuidePageImg src={`${process.env.PUBLIC_URL}/img/guide/money-guide-7.png`} alt="" />
      </MobileMoneyGuidePageInnerWrap>
    </MobileMoneyGuidePageWrap>
  )
}

export default MobileMoneyGuidePage

const MobileMoneyGuidePageWrap = styled.div`
  width: 100%;
  background: #f8f8f6;
  padding: 12px;
`
const MobileMoneyGuidePageHeader = styled.div`
  position: relative;
`
const MobileMoneyGuidePageH3 = styled.h3`
  display: block;
  position: relative;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.5rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #cf152d;
    bottom: 0;
    left: calc(50% - 20px);
  }
`

const MobileMoneyGuidePageInnerWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 1500px;

  border-radius: 16px;
  background: white;

  padding: 10px 10px;

  display: flex;
  flex-direction: column;

  gap: 10px;
`

const MobileMoneyGuidePageImg = styled.img`
  width: 100%;
  height: 100%;
`

const MobileMoneyGuidePageInnerTitle = styled.div`
  width: 100%;
  font-size: 22px;
  line-height: 25px;
  color: black;
`
