// session storage 에 아이디 저장
export function setUserId(userId) {
  sessionStorage.setItem('USER_ID', userId)
}

// session storage 에 아이디 가져오기
export function getUserId() {
  return sessionStorage.getItem('USER_ID')
}

/**
 * Local Storage Clear
 */
export function clearLocalStorage() {
  sessionStorage.clear()
}

export const isLogin = () => !!getUserId()
